import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styles from "./ReservationExpired.module.css";
import { LinkButton } from "components/LinkButton";

export class ReservationExpiredView extends React.Component {
  handleBookingRetry = (evt) => {
    this.props.dispatch(push("/"));
  };

  render() {
    return (
      <div className={styles.root}>
        <p>Your reservation has expired</p>
        <LinkButton
          onClick={this.handleBookingRetry}
          className={`${styles.tryAgainButton} reservationExpiredTryAgainButton`}
        >
          Back to ticket selection
        </LinkButton>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(ReservationExpiredView);
