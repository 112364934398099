/**
 * This is a special view which connects the state to the LaddaButton only. Using this
 * view as a Button is better than passing around the custom colour throughout the
 * application.
 *
 * This supports all the props the that LaddaButton component does
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import tinycolor from 'tinycolor2'
import LaddaButton from 'components/LaddaButton'

class LaddaButtonView extends React.Component {

  static propTypes = {
    mainColor: PropTypes.string.isRequired
  }

  getSpinnerColor = () => {
    let color = tinycolor(this.props.mainColor)
    let textColor = null

    if (color.isDark()) {
      textColor = tinycolor('#fff')
    } else {
      textColor = tinycolor('#333')
    }

    return textColor.toHexString()
  }

  render() {
    let spinnerColor = this.getSpinnerColor()

    return <LaddaButton spinnerColor={spinnerColor} {...this.props}>{this.props.children}</LaddaButton>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mainColor: state.settings.mainColor
  }
}

export default connect(mapStateToProps)(LaddaButtonView)
