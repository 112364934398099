import { connect } from "react-redux";
import TicketTableFooter from "components/TicketTableFooter";
import {
  getBasketItemCount,
  getBasketTotal,
  getBasketCurrency,
  getBasketDiscount,
  getBasketTax,
  getSelectedPerformanceSoldOut,
  getBasketIsValid,
} from "selectors";

const mapStateToProps = (state, ownProps) => {
  return {
    basketItemCount: getBasketItemCount(state),
    basketTotal: getBasketTotal(state),
    basketTax: getBasketTax(state),
    basketCurrency: getBasketCurrency(state),
    discount: getBasketDiscount(state),
    listingsLink: state.settings.listingsLink,
    isSoldOut: getSelectedPerformanceSoldOut(state),
    showWaitingList: state.settings.showWaitingList,
    basketIsValid: getBasketIsValid(state),
  };
};

export default connect(mapStateToProps)(TicketTableFooter);
