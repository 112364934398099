import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./BookingInfo.module.css";

export class BookingInfoView extends React.Component {
  static propTypes = {
    booking: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  getBookingHtml = () => {
    const message = this.props.isLoading
      ? "Please wait, loading booking information"
      : this.props.booking;

    return {
      __html: message,
    };
  };

  render() {
    return (
      <div className={styles.root}>
        <h2>Booking Info</h2>
        <p dangerouslySetInnerHTML={this.getBookingHtml()}></p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.booking.isFetching,
    booking: state.booking.item,
  };
};

export default connect(mapStateToProps)(BookingInfoView);
