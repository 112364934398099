import React from "react";
import Select from "react-select";

const SelectField = ({
  input,
  label,
  placeholder,
  options,
  isMulti,
  meta: { touched, error, warning },
}) => {
  const styles = {
    container: (styles) => ({
      ...styles,
      fontFamily: "lato, sans serif",
      fontSize: "0.85rem",
      marginTop: "0.75rem",
      marginBottom: "0.75rem",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderWidth: "0.09rem",
      borderColor: "#ddd",
      borderRadius: "0.2rem",
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0 0.5rem",
    }),
  };

  return (
    <div>
      <label>{label}</label>
      <Select
        {...input}
        options={options}
        onBlur={() => null}
        styles={styles}
        isClearable
        isMulti={isMulti}
        isSearchableboolean={false}
        placeholder={placeholder}
      />
      {touched &&
        ((error && <span className="form__error">{error}</span>) ||
          (warning && <span className="form__error">{warning}</span>))}
    </div>
  );
};

export default SelectField;
