import numeral from "numeral";

/**
 * Covert currency code to symbol
 *
 * TODO: maybe use currency-symbol-map instead, it has more currencies
 *
 */
export const currencyCodeToSymbol = (currenyCode) => {
  const supportedCurrencies = {
    GBP: "£",
    USD: "$",
    EUR: "€",
    CAD: "$",
    AUD: "$",
    ZAR: "R",
    DKK: "kr",
    CHF: "CHF ",
  };

  if (supportedCurrencies.hasOwnProperty(currenyCode)) {
    return supportedCurrencies[currenyCode];
  }
};

export const formatPrice = (price) => {
  return numeral(price).format("0,0.00");
};
