import React from "react";
import styles from "./FormField.module.css";

const FormField = ({
  input,
  label,
  type,
  hideLabel,
  formClass,
  autoComplete,
  meta: { touched, error },
}) => (
  <div className={formClass}>
    <label className={styles.visibleLabel} hidden={hideLabel}>
      {label}
    </label>
    <input
      {...input}
      autoComplete={autoComplete}
      placeholder={label}
      type={type}
      className={touched && error ? styles.formInputError : styles.formInput}
    />
    {touched && error && <span className={styles.formError}>{error}</span>}
  </div>
);

export default FormField;
