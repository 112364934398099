/*
 * For code we using the spelling 'color' and comments use the proper 'colour'
 * This is what happens when you let the yanks invent css.
 *
 */

import tinycolor from "tinycolor2";

export default class CustomColors {
  constructor(settings) {
    this.settings = settings;
  }
  /**
   * This create colour variations based on the mainColor, currently it generates
   * a text colour that can be used on elements where the background colour is
   * the main colour
   *
   */
  createForegroundSpecificColors = (mainColor) => {
    let color = tinycolor(mainColor);
    let textColor = null;

    /* XXX: readability doesn't give great results for the default theme
    let readabilityOptions = {
      includeFallbackColors: false,
      level: 'AAA',
      size: 'small'
    }
    let textColor = tinycolor.mostReadable(color, ['#ffffff', '#000000'], readabilityOptions)
    */

    if (color.isDark()) {
      textColor = tinycolor("#fff");
    } else {
      textColor = tinycolor("#333");
    }

    let colors = {
      TextColor: textColor.toHexString(),
    };

    return colors;
  };

  /**
   * Special colours for specific elements based on the background color of
   * the widget
   *
   * @param {string} backgroundColorString The background colour of the widget
   *
   */
  createBackgroundSpecificColors = (backgroundColorString) => {
    let color = tinycolor(backgroundColorString);
    let border = null;
    let borderFocus = null;
    let faded = null;
    let fadedFocus = null;

    if (color.isDark()) {
      border = color.clone().lighten(30);
      borderFocus = border.clone().lighten(30);
      faded = color.clone().lighten(30);
      fadedFocus = faded.clone().lighten(30);
    } else {
      border = color.clone().darken(20);
      borderFocus = border.clone().darken(20);
      faded = color.clone().darken(30);
      fadedFocus = faded.clone().darken(30);
    }

    return {
      borderColor: border.toHexString(),
      borderFocusColor: borderFocus.toHexString(),
      fadedColor: faded.toHexString(),
      fadedFocusColor: fadedFocus.toHexString(),
    };
  };

  /**
   * This will a light, dark and compliment version of the passed in color
   *
   * @param {string} colorString The colour as a hex string
   *
   */
  createColorVariations = (colorString) => {
    let color = tinycolor(colorString);
    let dark = color.clone().darken(10);
    let light = color.clone().lighten(20);

    let rgb = color.toRgb();
    let complement = tinycolor({
      r: 255 - rgb.r,
      g: 255 - rgb.g,
      b: 255 - rgb.b,
    }).lighten(20);

    let complementDark = complement.clone().darken(20);
    let complementLight = complement.clone().lighten(35);

    let colors = {
      Light: light.toHexString(),
      Dark: dark.toHexString(),
      Complement: complement.toHexString(),
      ComplementDark: complementDark.toHexString(),
      ComplementLight: complementLight.toHexString(),
    };

    return colors;
  };

  /**
   * This looks at the settings props for keys containing 'color', when it finds
   * one, it'll parse the colour and create a light, dark and complement
   * version of that colour, using the keys:
   *
   */
  processColorSettings = () => {
    // copy, rather than altering the original settings object
    let settings = Object.assign({}, this.settings);

    for (let key in settings) {
      // for each color setting, create variations of the color automatically
      if (key.match(/color/i)) {
        let variations = this.createColorVariations(settings[key]);
        for (let variationKey in variations) {
          settings[key + variationKey] = variations[variationKey];
        }
      }

      // create colours for different style properties based on the background colour
      if (key === "backgroundColor") {
        let variations = this.createBackgroundSpecificColors(settings[key]);
        for (let variationKey in variations) {
          settings[variationKey] = variations[variationKey];
        }
      }

      // create a color for text used in buttons, tabs etc, where the main colour is used
      if (key === "mainColor") {
        let variations = this.createForegroundSpecificColors(settings[key]);
        for (let variationKey in variations) {
          settings[key + variationKey] = variations[variationKey];
        }
      }
    }
    return settings;
  };
}
