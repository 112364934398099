import React from "react";
import { connect } from "react-redux";
import LaddaButton from "components/LaddaButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import buildUrl from "build-url";
import { getPerformanceDateString } from "utils/DateFormatting";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import { cancelTicket } from "actions";
import { getBookingFeeText } from "utils/Text";
import styles from "./OrderDetail.module.css";

class OrderDetail extends React.Component {
  getOrderPdfDownloadUrl = (order) => {
    let url = buildUrl(process.env.REACT_APP_API_SERVER, {
      path: `order/download/${order.id}/`,
      queryParams: {
        token: this.props.auth.authorization,
        apiKey: this.props.auth.apiKey,
      },
    });

    return url;
  };

  getSubtotal = () => {
    let total = 0;
    for (let purchase of this.props.order.ticketPurchases) {
      let basePrice = purchase.ticket.faceValueTotal;
      total += basePrice * purchase.quantity;
    }
    return total;
  };

  getBookingFees = () => {
    let total = 0;
    for (let purchase of this.props.order.ticketPurchases) {
      let orderBookingFee = purchase.ticket.outsideFeeTotal;
      total += orderBookingFee * purchase.quantity;
    }
    return total;
  };

  getDiscount = () => {
    return (
      this.getBookingFees() +
      this.props.order.totalExFees -
      this.props.order.total
    );
  };
  onCancelTicketClick = (e, receiptId) => {
    e.preventDefault();
    this.props.dispatch(cancelTicket(receiptId));
  };

  getLineItem = (lineItem) => {
    let purchase = lineItem.map((purchase) => {
      let isFree =
        purchase.ticket.total === 0 || purchase.ticket.total == null
          ? true
          : false;
      let dateStr = getPerformanceDateString(purchase.performance);
      let currencySymbol = currencyCodeToSymbol(purchase.ticket.currency);

      if (isFree) {
        return (
          <div key={purchase.id} className={styles.purchaseItem}>
            {purchase.receipts.map((receipt) => (
              <div className={styles.purchaseRow}>
                <span key={receipt.id}>
                  <div className={styles.purchaseInfo}>
                    <div>
                      <p>
                        {dateStr} - {purchase.ticket.description}
                      </p>
                    </div>
                  </div>
                  <div className={styles.purchasePrice}>
                    {receipt.cancelled ? (
                      <p>Cancelled</p>
                    ) : (
                      <div>
                        <div className={styles.purchasePricePrice}>
                          <p>FREE</p>
                        </div>
                        <div className={styles.purchasePriceAction}>
                          <LaddaButton
                            loading={receipt.loading}
                            className={`${styles.cancelTicketButton} orderDetailCancelTicketButton`}
                            onClick={(e) =>
                              this.onCancelTicketClick(e, receipt.id)
                            }
                          >
                            Cancel Ticket
                          </LaddaButton>
                        </div>
                      </div>
                    )}
                  </div>
                </span>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div key={purchase.id} className={styles.purchaseItem}>
            {purchase.receipts.map((receipt) => (
              <div className={styles.purchaseRow}>
                <span key={receipt.id}>
                  <div className={styles.purchaseInfo}>
                    <div>
                      <p>
                        {dateStr} - {purchase.ticket.description}
                      </p>
                    </div>
                  </div>
                  <div className={styles.purchasePrice}>
                    {receipt.cancelled ? (
                      <p>
                        <b>Cancelled</b>
                      </p>
                    ) : (
                      <div>
                        <div className={styles.purchasePricePrice}>
                          <p>
                            {currencySymbol}
                            {formatPrice(receipt.total)}
                          </p>
                        </div>
                        <div className={styles.purchasePriceAction}></div>
                      </div>
                    )}
                  </div>
                </span>
              </div>
            ))}
          </div>
        );
      }
    });
    return (
      <div className={styles.eventSummary} key={lineItem[0].event.id}>
        <h4>{lineItem[0].event.title}</h4>
        {purchase}
      </div>
    );
  };
  /**
   * Get html for the right panel which shows the purchased order information
   *
   */
  getOrderBreakdownPanel = () => {
    let totalsSummary = null;

    // build list of tickets that were purchased
    const groupedPurchases = this.props.order.ticketPurchases.reduce(
      (rv, purchase) => {
        (rv[purchase.event.id] = rv[purchase.event.id] || []).push(purchase);
        return rv;
      },
      {}
    );
    const purchasedLineItems = Object.keys(groupedPurchases).map(
      (id) => groupedPurchases[id]
    );
    let purchases = purchasedLineItems.map((lineItem) =>
      this.getLineItem(lineItem)
    );

    // if user had to pay, show the totals
    if (this.props.order.total > 0) {
      const symbol = currencyCodeToSymbol(this.props.order.currency);
      const subTotal = formatPrice(this.getSubtotal());
      const bookingFees = formatPrice(this.getBookingFees());
      const total = formatPrice(this.props.order.total);
      const feeText =
        this.props.order.tax > 0
          ? getBookingFeeText(this.props.order.currency)
          : "";
      const hasFees = bookingFees > 0;

      const discount = formatPrice(this.getDiscount());
      const hasDiscount = discount > 0;
      var ticketDiscount;
      if (hasDiscount) {
        ticketDiscount = (
          <div className={styles.subTotal}>
            <p>Discount</p>
            <p className={styles.subTotalFigure}>
              - {symbol}
              {discount}
            </p>
          </div>
        );
      }
      if (hasFees) {
        totalsSummary = (
          <div className={styles.totals}>
            <div className={styles.subTotal}>
              <p>Subtotal</p>
              <p className={styles.subTotalFigure}>
                {symbol}
                {subTotal}
              </p>
            </div>
            <div className={styles.subTotal}>
              <p>Booking fees</p>
              <p className={styles.subTotalFigure}>
                {symbol}
                {bookingFees}
              </p>
            </div>
            {ticketDiscount}
            <div className={styles.total}>
              <span className={styles.totalLabel}>Total</span>{" "}
              <span className={styles.totalValue}>
                {symbol}
                {total}
              </span>
            </div>
            <p className={styles.totalFooter}>{feeText}</p>
          </div>
        );
      } else {
        totalsSummary = (
          <div className={styles.totals}>
            {ticketDiscount}
            <div className={styles.total}>
              <span className={styles.totalLabel}>Total</span>{" "}
              <span className={styles.totalValue}>
                {symbol}
                {total}
              </span>
            </div>
            <p className={styles.totalFooter}>{feeText}</p>
          </div>
        );
      }
    }

    return (
      <div className={styles.panel}>
        <div className={styles.downloadTicketsContainer}>
          <a
            href={this.getOrderPdfDownloadUrl(this.props.order)}
            className={`${styles.downloadTicketsButton} orderDetailDownloadTicketsButton`}
          >
            Download Receipts
          </a>
        </div>
        {purchases}
        {totalsSummary}
      </div>
    );
  };

  render = () => {
    return (
      <div className={styles.root}>
        <div className={styles.backButton}>
          <Link to={"/past-orders"}>
            <span className={styles.backButtonSpan}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back to orders
          </Link>
        </div>
        <h2>Order Details</h2>
        <div className={styles.row}>
          <div className={styles.orderBreakdownPanel}>
            {this.getOrderBreakdownPanel()}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    order: state.pastOrders.selectedOrder,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(OrderDetail);
