import React from "react";
import PropTypes from "prop-types";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import styles from "./TicketRow.module.css";
import { LinkButton } from "components/LinkButton";

export default class TicketRow extends React.Component {
  static propTypes = {
    ticket: PropTypes.object.isRequired,
    performance: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired,
    onIncrementClick: PropTypes.func.isRequired,
    onDecrementClick: PropTypes.func.isRequired,
    capacity: PropTypes.object,
  };

  /**
   * Returns true if more of this ticket can be added to the basket
   *
   */
  canTicketQuantityBeIncremented = () => {
    let capAvailable = null;
    let inventoryAvailable =
      this.props.quantity < this.props.ticket.inventoryLeft;

    if (this.props.capacity) {
      if (this.props.capacity.left > 0) {
        capAvailable = true;
      } else {
        capAvailable = false;
      }
    } else {
      capAvailable = true;
    }

    return inventoryAvailable && capAvailable;
  };

  /**
   * Returns true if the item is sold out
   *
   */
  isSoldOut = () => {
    let soldOut = false;
    let inventory = this.props.ticket.inventoryLeft + this.props.quantity;
    if (inventory === 0) {
      soldOut = true;
    }

    if (this.props.capacity) {
      let capacity = this.props.capacity.left + this.props.quantity;
      if (capacity === 0) {
        soldOut = true;
      }
    }
    return soldOut;
  };

  /**
   * Called when decrement button is clicked
   *
   */
  handleDecrementQuantity = (evt) => {
    evt.preventDefault();

    if (this.props.quantity > 0) {
      let qty =
        this.props.quantity === this.props.ticket.minPerOrder
          ? this.props.ticket.minPerOrder
          : 1;

      this.props.onDecrementClick(
        this.props.ticket,
        this.props.performance,
        qty
      );
    }
  };

  /**
   * Called when increment button is clicked
   *
   */
  handleIncrementQuantity = (evt) => {
    evt.preventDefault();

    if (this.canTicketQuantityBeIncremented()) {
      let qty =
        this.props.quantity < this.props.ticket.minPerOrder
          ? this.props.ticket.minPerOrder
          : 1;
      this.props.onIncrementClick(
        this.props.ticket,
        this.props.performance,
        qty
      );
    }
  };

  /**
   * This will cut the description to `cutoff` length. The resulting text will
   * be `cutoff`+ 3 in length. It will also indicate whether the text was
   * trimmed, so that the tooltip can be excluded if there was no need to trim.
   *
   */
  getDescriptionCroppingInfo = () => {
    let text = this.props.ticket.description;
    let cropped = false;
    const cutoff = 150;

    if (text.length > cutoff) {
      text = text.substr(0, cutoff) + "...";
      cropped = true;
    }

    return { text, cropped };
  };

  /**
   * Returns the description wrapped in a tooltip or not, depending on the
   * length of the text
   *
   */
  getDescriptionBlock = () => {
    const { text, cropped } = this.getDescriptionCroppingInfo();

    if (cropped) {
      return (
        <div>
          <div
            className={styles.descriptionTooltip}
            data-balloon={this.props.ticket.description}
            data-balloon-pos={"up"}
            data-balloon-length={"medium"}
          >
            <span className={styles.description}>{text}</span>
          </div>
          <div
            className={styles.descriptionTooltipMobile}
            data-balloon={this.props.ticket.description}
            data-balloon-pos={"right"}
            data-balloon-length={"small"}
          >
            <span className={styles.description}>{text}</span>
          </div>
        </div>
      );
    } else {
      return (
        <span>
          <span className={styles.description}>{text}</span>
          {this.props.ticket.detail && (
            <span className={styles.descriptionDetail}>
              {this.props.ticket.detail}
            </span>
          )}
        </span>
      );
    }
  };

  getQuantityBlock = () => {
    const soldOut = this.isSoldOut();
    let addClassName = this.canTicketQuantityBeIncremented()
      ? `${styles.add} ticketRowAdd`
      : `${styles.addDisabled} ticketRowAddDisabled`;
    let removeClassName =
      this.props.quantity === 0
        ? `${styles.removeDisabled} ticketRowRemoveDisabled`
        : `${styles.remove} ticketRowRemove`;
    let showRemaining = this.props.lowInventoryLevel > 0;
    if (soldOut) {
      return (
        <td className={styles.controlsColumn}>
          <span className={styles.soldOut}>Sold Out</span>
        </td>
      );
    } else {
      return (
        <td className={styles.controlsColumn}>
          <LinkButton
            className={removeClassName}
            onClick={this.handleDecrementQuantity}
          >
            -
          </LinkButton>
          <span className={styles.quantity}>{this.props.quantity}</span>
          <LinkButton
            className={addClassName}
            onClick={this.handleIncrementQuantity}
          >
            +
          </LinkButton>
          {showRemaining && (
            <span className={styles.remainingMobile}>
              {this.getRemainingText()}
            </span>
          )}
        </td>
      );
    }
  };

  /**
   * Returns the pricing details for the ticket
   *
   */
  getPricingBlock = () => {
    let currencySymbol = currencyCodeToSymbol(this.props.ticket.currency);
    let isFree =
      this.props.ticket.total === 0 || this.props.ticket.total == null
        ? true
        : false;
    let fees = this.props.ticket.feeTotal;
    let total = this.props.ticket.feesPassed
      ? this.props.ticket.totalExFees
      : this.props.ticket.total;
    let discount = this.props.ticket.discount || 0;
    total -= discount;
    if (isFree) {
      return (
        <td className={styles.pricesColumn}>
          <span className={styles.total}>FREE</span>
        </td>
      );
    } else {
      if (this.props.ticket.feesPassed) {
        return (
          <td className={styles.pricesColumn}>
            <span className={styles.total}>
              {currencySymbol}
              {formatPrice(total)}
            </span>
            <span className={`${styles.fees} ticketRowFees`}>
              (+ {currencySymbol}
              {formatPrice(fees)} fee)
            </span>
          </td>
        );
      } else {
        return (
          <td className={styles.pricesColumn}>
            <span className={styles.total}>
              {currencySymbol}
              {formatPrice(total)}
            </span>
          </td>
        );
      }
    }
  };

  getRemainingText = () => {
    let cap = this.props.capacity
      ? this.props.capacity.left
      : this.props.ticket.inventoryLeft;
    let ticketsRemaining =
      Math.min(this.props.ticket.inventoryLeft, cap) - this.props.quantity;
    if (
      ticketsRemaining < this.props.lowInventoryLevel &&
      ticketsRemaining > 0
    ) {
      return `Only ${ticketsRemaining} left`;
    }
    return "";
  };
  render = () => {
    return (
      <tr>
        <td className={styles.descriptionColumn}>
          {this.getDescriptionBlock()}
        </td>
        {this.getPricingBlock()}
        {this.getQuantityBlock()}
      </tr>
    );
  };
}
