import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { fetchPastOrders, selectPastOrder, loginAndFetchCards } from "actions";
import LoginForm from "components/LoginForm";
import {
  getOrdersForPage,
  getOrdersCurrentPage,
  getOrdersTotalPages,
} from "selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./PastOrders.module.css";
import { LinkButton } from "components/LinkButton";

const OrderRow = ({ order, onViewOrderClick }) => {
  const handleOnClick = (evt) => {
    evt.preventDefault();
    onViewOrderClick(order);
  };

  return (
    <span className={styles.pastOrdersTableRow}>
      <span
        className={`${styles.pastOrdersTableCell} pastOrdersPastOrdersTableCell`}
      >
        {moment(order.createdAt).format("D MMM YYYY HH:MM")}
      </span>
      <span
        className={`${styles.pastOrdersTableCell} pastOrdersPastOrdersTableCell`}
      >
        {order.reference}
      </span>
      <span
        className={`${styles.pastOrdersTableCell} pastOrdersPastOrdersTableCell`}
      >
        <LinkButton onClick={handleOnClick}>View Order</LinkButton>
      </span>
    </span>
  );
};
export class PastOrders extends React.Component {
  componentDidMount = () => {
    const { dispatch, page } = this.props;
    dispatch(fetchPastOrders(page));
  };

  componentWillReceiveProps(nextProps) {
    const { dispatch, page, auth } = nextProps;
    if (
      page !== this.props.page ||
      auth.loggedIn !== this.props.auth.loggedIn
    ) {
      dispatch(fetchPastOrders(page));
    }
  }
  handleOnViewOrderClick = (order) => {
    this.props.dispatch(selectPastOrder(order));
    this.props.dispatch(push("/past-order"));
  };
  submit = (values) => {
    return this.props.dispatch(loginAndFetchCards(values));
  };

  handleLoadNextPage = (e) => {
    e.preventDefault();
    const { dispatch, page, totalPages } = this.props;
    if (page < totalPages) {
      dispatch(fetchPastOrders(page + 1));
    }
  };
  handleLoadPreviousPage = (e) => {
    e.preventDefault();
    const { dispatch, page } = this.props;
    if (page > 1) {
      dispatch(fetchPastOrders(page - 1));
    }
  };
  render = () => {
    return (
      <div className={styles.root}>
        <div className={styles.backButton}>
          <Link to={"/"}>
            <span className={styles.backButtonSpan}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back to tickets
          </Link>
        </div>

        {this.props.auth.loggedIn ? (
          <span>
            <h2>My Past Orders</h2>
            <span className={styles.pastOrdersTable}>
              <span className={styles.pastOrdersTableHeader}>
                <span className={styles.pastOrdersTableHeaderCell}>
                  Date Order Placed
                </span>
                <span className={styles.pastOrdersTableHeaderCell}>
                  Order Reference
                </span>
                <span></span>
              </span>
              <span className={styles.pastOrdersTableBody}>
                {this.props.orders.map((order) => (
                  <OrderRow
                    key={order.id}
                    order={order}
                    onViewOrderClick={this.handleOnViewOrderClick}
                  />
                ))}
              </span>
            </span>
            <span className={`${styles.pagination} pastOrdersPagination`}>
              {this.props.page > 1 && (
                <LinkButton onClick={this.handleLoadPreviousPage}>
                  <FontAwesomeIcon
                    icon="chevron-left"
                    className={styles.paginationIconLeft}
                  />{" "}
                  Previous Page
                </LinkButton>
              )}
              {this.props.page < this.props.totalPages && (
                <LinkButton onClick={this.handleLoadNextPage}>
                  Next Page{" "}
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className={styles.paginationIcon}
                  />
                </LinkButton>
              )}
            </span>
          </span>
        ) : (
          <div className={styles.loginContainer}>
            <div className={styles.loginHeader}>
              <h2>Welcome back</h2>
            </div>
            <LoginForm onSubmit={this.submit} />
            <Link to={"/forgot-password"} className={styles.forgotPassword}>
              Forgot password?
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const currentPage = getOrdersCurrentPage(state);
  return {
    orders: getOrdersForPage(state, currentPage),
    totalPages: getOrdersTotalPages(state),
    page: currentPage,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(PastOrders);
