import React from "react";
import PropTypes from "prop-types";

import styles from "./Footer.module.css";
import { LinkButton } from "components/LinkButton";

export default class Footer extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    onLogoutClick: PropTypes.func.isRequired,
  };

  handleLogoutClick = (evt) => {
    evt.preventDefault();
    this.props.onLogoutClick();
  };

  render = () => {
    if (!this.props.auth.loggedIn) {
      return (
        <div className={`${styles.root} footerRoot`}>
          <span className={styles.powerBy}>
            <a href="https://lineupnow.com" target="_blank" rel="noreferrer">
              Powered by Line-Up
            </a>
          </span>
        </div>
      );
    }

    return (
      <div className={`${styles.root} footerRoot`}>
        <span className={styles.powerBy}>
          <a href="https://lineupnow.com" target="_blank" rel="noreferrer">
            Powered by Line-Up
          </a>
        </span>
        <span className={styles.userInformation}>
          Hello, {this.props.user.firstName}.{" "}
          <LinkButton onClick={this.handleLogoutClick}>Logout</LinkButton>
        </span>
      </div>
    );
  };
}
