import React from "react";
import styles from "./Spinner.module.css";

const Spinner = () => {
  return (
    <div className={styles.root}>
      <div className={`${styles.cube1 + " " + styles.cube} spinnerCube`}></div>
      <div className={`${styles.cube2 + " " + styles.cube} spinnerCube`}></div>
      <div className={`${styles.cube4 + " " + styles.cube} spinnerCube`}></div>
      <div className={`${styles.cube3 + " " + styles.cube} spinnerCube`}></div>
    </div>
  );
};
export default Spinner;
