import React, { useEffect } from "react";
import { push } from "connected-react-router";
import styles from "./CustomForm.module.css";
import CustomForm from "components/CustomForm";
import Spinner from "components/Spinner";
import { fetchForm, updateCustomForm } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

const CustomFormView = () => {
  const dispatch = useDispatch();
  const { formId } = useParams();

  const { isFetched, item: customForm } = useSelector(
    (state) => state.customForm
  );

  useEffect(() => {
    formId && dispatch(fetchForm(formId));
  }, [formId, dispatch]);

  const handleSubmit = (values) => {
    return dispatch(updateCustomForm(formId, values));
  };
  const handleSuccess = () => {
    dispatch(push("/confirm"));
  };

  if (!isFetched) {
    return <Spinner />;
  } else {
    return (
      <div className={styles.root}>
        <div>
          <div className={styles.formHeader}>
            <h2>{customForm.name}</h2>
          </div>
          <div className={styles.root}>
            <CustomForm
              fields={customForm.fields}
              description={customForm.description}
              onSubmit={handleSubmit}
              onSubmitSuccess={handleSuccess}
            />
          </div>
        </div>
      </div>
    );
  }
};

export { CustomFormView };
