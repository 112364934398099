import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sendForgotPasswordEmail } from "actions";
import ForgotPasswordForm from "components/ForgotPasswordForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ForgotPassword.module.css";

export class ForgotPasswordView extends React.Component {
  handleSubmitClick = (values) => {
    return this.props.dispatch(sendForgotPasswordEmail(values.email));
  };
  getSentSuccessMessage = () => {
    return (
      <div className={styles.forgotPasswordSuccess}>
        <p>
          An email has been sent with instructions on how to reset your
          password. Once you have reset your password, please click the button
          to go back to login:
        </p>
        <div className={styles.loginButtonContainer}>
          <Link
            to={"/login"}
            className={`${styles.loginButton} forgotPasswordFormSubmitButton`}
          >
            Back to login
          </Link>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div className={styles.root}>
        <div className={styles.backButton}>
          <Link to={"/login"}>
            <span className={styles.backButtonSpan}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back
          </Link>
        </div>
        <div className={styles.forgotPasswordContainer}>
          <h2>Forgot password</h2>
          {this.props.isSent ? (
            this.getSentSuccessMessage()
          ) : (
            <ForgotPasswordForm onSubmit={this.handleSubmitClick} />
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    isSent: state.forgotPassword.isSent,
  };
};

export default connect(mapStateToProps)(ForgotPasswordView);
