import React from "react";
import { connect } from "react-redux";
import AddressForm from "components/AddressForm";
import { updateUser } from "actions";
import styles from "./AddressView.module.css";
import { push } from "connected-react-router";

class AddressView extends React.Component {
  handleSubmit = (values) => {
    return this.props.dispatch(updateUser(values));
  };

  handleSuccess = () => {
    if (this.props.customFormId) {
      this.props.dispatch(push(`/form/${this.props.customFormId}`));
    } else {
      this.props.dispatch(push(`/confirm`));
    }
  };

  render() {
    return (
      <div className={styles.root}>
        <div>
          <div className={styles.formHeader}>
            <h2>Address Details</h2>
          </div>
          <AddressForm
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.handleSuccess}
            initialValues={{
              address: {
                country: "GB",
              },
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    customFormId: state.settings.customFormId,
  };
};
export default connect(mapStateToProps)(AddressView);
