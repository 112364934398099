import React from "react";
import { useDispatch } from "react-redux";
import styles from "./AddDiscount.module.css";
import { applyDiscountCode } from "actions";
import DiscountForm from "components/DiscountForm";
import { useHistory } from "react-router";

const AddDiscountView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleApplyDiscountCode = (discountData) => {
    return dispatch(applyDiscountCode(discountData.code));
  };
  const handleSubmitSuccess = () => {
    history.goBack();
  };

  return (
    <div className={styles.root}>
      <DiscountForm
        onSubmit={handleApplyDiscountCode}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </div>
  );
};

export { AddDiscountView };
