import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./Modal.module.css";
import close from "./close.png";

export default class Modal extends React.Component {
  static propTypes = {
    returnTo: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div ref="root" className={`${styles.root} modalRoot`}>
        <div className={styles.modalContent}>
          <div className={styles.modalClose}>
            <Link to={this.props.returnTo} className={styles.closeView}>
              <img src={close} alt="close" />
            </Link>
          </div>
          <div className={styles.modalMain}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
