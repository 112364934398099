import React from "react";
import PropTypes from "prop-types";
import { getPerformanceDateString } from "utils/DateFormatting";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import styles from "./BasketItemRow.module.css";

export default class BasketItemRow extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
  };

  handleRemoveClick = (evt) => {
    evt.preventDefault();
    this.props.onRemoveClick(this.props.item);
  };

  /**
   * Returns the pricing details for the individual ticket
   *
   */
  getPricingBlock = () => {
    let currencySymbol = currencyCodeToSymbol(this.props.item.ticket.currency);
    let isFree =
      this.props.item.ticket.total === 0 || this.props.item.ticket.total == null
        ? true
        : false;

    let total = this.props.item.ticket.feesPassed
      ? this.props.item.ticket.totalExFees
      : this.props.item.ticket.total;

    if (isFree) {
      return <span className={styles.faceValue}>FREE</span>;
    } else {
      return (
        <span className={styles.faceValue}>
          {currencySymbol}
          {formatPrice(total)}
        </span>
      );
    }
  };

  /**
   * Returns pricing for the line item as a whole
   *
   */
  getItemPricingBlock = () => {
    let currencySymbol = currencyCodeToSymbol(this.props.item.ticket.currency);
    let isFree =
      this.props.item.ticket.total === 0 || this.props.item.ticket.total == null
        ? true
        : false;
    let total = this.props.item.ticket.feesPassed
      ? this.props.item.ticket.totalExFees
      : this.props.item.ticket.total;
    let groupedTotal = total * this.props.item.quantity;
    if (isFree) {
      return (
        <div className={styles.groupedValue}>
          <p>FREE</p>
        </div>
      );
    } else {
      return (
        <div className={styles.groupedValue}>
          <p>
            {currencySymbol}
            {formatPrice(groupedTotal)}
          </p>
        </div>
      );
    }
  };

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.ticketInfo}>
          <div className={styles.basketItemDetails}>
            {this.props.item.performance && (
              <p>{getPerformanceDateString(this.props.item.performance)}</p>
            )}
            <p>
              {this.props.item.ticket.description} ({this.getPricingBlock()}) x{" "}
              {this.props.item.quantity}
            </p>
            <p>
              {this.props.item.seats.map((seat, index) => (
                <span className={styles.basketItemSeat} key={index}>
                  {seat}
                </span>
              ))}
            </p>
            <p
              className={`${styles.basketItemRemove} basketItemRowBasketItemRemove`}
              onClick={this.handleRemoveClick}
            >
              (Remove)
            </p>
          </div>
          <div className={styles.ticketPrice}>{this.getItemPricingBlock()}</div>
        </div>
      </div>
    );
  }
}
