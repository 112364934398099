import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormField from '../FormField';
import LaddaButton from '../LaddaButton';
import styles from './LoginForm.module.css';

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 6) {
    errors.password = 'Your password needs to be at least 6 characters long';
  }
  return errors;
};

let LoginForm = ({ error, handleSubmit, pristine, submitting }) => {
  return (
    <form onSubmit={handleSubmit} className={styles.registerForm}>
      <div className={styles.loginFormForm}>
        {error && <div className={styles.formError}>{error}</div>}
        <div>
          <Field
            name='email'
            component={FormField}
            type='email'
            label='Email Address'
            hideLabel={true}
            autoComplete='email'
          />
        </div>
        <div>
          <Field
            name='password'
            component={FormField}
            type='password'
            label='Password'
            hideLabel={true}
          />
        </div>
        <div>
          <LaddaButton
            type='submit'
            disabled={submitting}
            className={`${styles.loginButton} loginFormLoginButton`}
            loading={submitting}
            onClick={handleSubmit}
          >
            Login
          </LaddaButton>
        </div>
      </div>
    </form>
  );
};

LoginForm = reduxForm({
  form: 'login',
  validate
})(LoginForm);

export default LoginForm;
