import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormField from '../FormField';
import LaddaButton from '../LaddaButton';
import styles from './DiscountForm.module.css';

const validate = values => {
  const errors = {};
  if (!values.code) {
    errors.code = 'Please enter a Discount Code';
  }
  return errors;
};

let DiscountForm = ({ error, handleSubmit, pristine, submitting }) => {
  return (
    <div className={styles.discountForm}>
      <form className={styles.root} onSubmit={handleSubmit}>
        {error && <div className={styles.formError}>{error}</div>}
        <div className={styles.inputContainer}>
          <Field
            formClass={styles.donationFormInput}
            name='code'
            component={FormField}
            type='text'
            label='Discount or Voucher code'
            hideLabel={true}
          />
        </div>
        <div className={styles.discountAppyButtonContainer}>
          <LaddaButton
            loading={submitting}
            className={`${styles.discountApplyButton} discountFormDiscountApplyButton`}
            onClick={handleSubmit}
            disabled={submitting || pristine}
          >
            Apply
          </LaddaButton>
        </div>
      </form>
    </div>
  );
};

DiscountForm = reduxForm({
  form: 'discount',
  validate
})(DiscountForm);

export default DiscountForm;
