import ReactPixel from "react-facebook-pixel";
import { LOCATION_CHANGE } from "connected-react-router";
import { getTicketsInBasket, getSeasonTicketsInBasket } from "selectors";
import {
  BASKET_ADD_TICKET,
  BASKET_ADD_SEASON_TICKET,
  CREATE_ORDER_SUCCESS,
  UPDATE_ORDER_SUCCESS,
  BOOK_NOW_CLICK,
  FETCH_EVENT_SETTINGS_SUCCESS,
} from "actions";

const trackPage = (page) => {
  ReactPixel.pageView();
};

let currentPage = "/";

const addProduct = (productItem) => {
  const { ticket, quantity } = productItem;
  return {
    id: ticket.id,
    quantity: quantity,
    item_price: ticket.total,
  };
};

const trackAddRemoveItem = (actionType, action) => {
  const { ticket } = action;
  ReactPixel.track("AddToCart", {
    value: ticket.total,
    currency: "GBP",
    content_ids: ticket.id,
    content_type: "product",
  });
};

const getBasket = (store) => {
  const tickets = getTicketsInBasket(store);
  const seasonTickets = getSeasonTicketsInBasket(store);
  const basket = [
    ...tickets.map((item) => addProduct(item)),
    ...seasonTickets.map((item) => addProduct(item)),
  ];
  return basket;
};

const trackPurchase = (store, response) => {
  if (response.data.invoice) {
    ReactPixel.track("Purchase", {
      value: response.data.total,
      currency: "GBP",
      contents: getBasket(store),
      content_type: "product",
    });
  }
};
const startCheckout = () => {
  ReactPixel.track("InitiateCheckout");
};
export const fbPixel = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_EVENT_SETTINGS_SUCCESS: {
      const settings = action.response.data;
      if (settings.fbPixel) {
        const options = {
          autoConfig: true,
          debug: false,
        };
        ReactPixel.init(settings.fbPixel, options);
      }
      break;
    }
    case LOCATION_CHANGE:
    case BASKET_ADD_TICKET:
    case BASKET_ADD_SEASON_TICKET:
    case BOOK_NOW_CLICK:
    case CREATE_ORDER_SUCCESS:
      if (store.getState().settings.fbPixel) {
        trackFbEvent(store, action);
      }
      break;
    default:
      break;
  }
  return next(action);
};

const trackFbEvent = (store, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const nextPage = `${action.payload.pathname}${action.payload.search}`;
      if (currentPage !== nextPage) {
        currentPage = nextPage;
        trackPage(nextPage);
      }
      break;
    }
    case BASKET_ADD_TICKET:
    case BASKET_ADD_SEASON_TICKET:
      trackAddRemoveItem("add", action);
      break;
    case BOOK_NOW_CLICK:
      startCheckout();
      break;
    case CREATE_ORDER_SUCCESS:
    case UPDATE_ORDER_SUCCESS:
      trackPurchase(store.getState(), action.response);
      break;
    default:
      break;
  }
};
