import React from "react";
import styles from "./FormField.module.css";

const FormField = ({
  input,
  label,
  type,
  hideLabel,
  shortField,
  placeholder,
  autoComplete,
  meta: { touched, error },
}) => {
  const Element = type === "textarea" ? "textarea" : "input";
  const rows = 3;
  return (
    <div>
      <label className={styles.visibleLabel} hidden={hideLabel}>
        {label}
      </label>
      <Element
        {...input}
        placeholder={placeholder}
        type={type}
        className={touched && error ? styles.formInputError : styles.formInput}
        rows={rows}
      />
      {touched && error && <span className={styles.formError}>{error}</span>}
    </div>
  );
};

export default FormField;
