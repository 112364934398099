import React from "react";
import { reduxForm, Field } from "redux-form";
import FormField from "./CustomFormField/FormField";
import SelectField from "./CustomFormField/SelectField";
import CheckboxGroup from "./CustomFormField/CheckboxGroup";
import RadioField from "./CustomFormField/RadioField";
import LaddaButton from "../LaddaButton";
import styles from "./CustomForm.module.css";

const required = (value) =>
  value ? undefined : "This information is required";
const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

const minZero = minValue(0);

let CustomForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  fields,
  description,
}) => {
  const getField = (field, floatRight) => {
    let className = field.shortField ? styles.shortField : "";
    let hideLabel = field.type === ("checkbox" || "radio") ? false : true;
    if (floatRight) {
      className += ` ${styles.floatRight}`;
    }
    let validators = field.required ? [required] : [];
    if (field.type === "number") {
      validators.push(minZero);
    }
    switch (field.type) {
      case "select":
        return (
          <div key={field.order} className={className}>
            <Field
              name={field.name}
              component={SelectField}
              isMulti={field.multiSelect}
              type={field.type}
              label={field.label}
              hideLabel={hideLabel}
              validate={validators}
              options={field.options}
            />
          </div>
        );
      case "checkbox":
        return (
          <div key={field.order}>
            <Field
              component={CheckboxGroup}
              options={field.options}
              name={field.name}
              groupLabel={field.label}
              type="checkbox"
              validate={validators}
            />
          </div>
        );
      case "radio":
        return (
          <div key={field.order}>
            <Field
              component={RadioField}
              options={field.options}
              name={field.name}
              groupLabel={field.label}
              type="radio"
              validate={validators}
            />
          </div>
        );
      default:
        return (
          <div key={field.order} className={className}>
            <Field
              name={field.name}
              component={FormField}
              type={field.type}
              label={field.label}
              hideLabel={hideLabel}
              placeholder={field.placeholder ? field.placeholder : field.label}
              validate={validators}
            />
          </div>
        );
    }
  };

  let floatRight;

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      {error && <div className={styles.formError}>{error}</div>}
      <p className={styles.formDescription}>{description}</p>
      {fields.map((field) => {
        floatRight = field.shortField ? floatRight : false;
        let fieldNode = getField(field, floatRight);
        floatRight = floatRight ? false : field.shortField ? true : false;
        return fieldNode;
      })}
      <div>
        <LaddaButton
          type="submit"
          disabled={submitting}
          className={`${styles.reviewButton} customFormReviewButton`}
          loading={submitting}
          onClick={handleSubmit}
        >
          Review Order
        </LaddaButton>
      </div>
    </form>
  );
};

CustomForm = reduxForm({
  form: "customForm",
})(CustomForm);

export default CustomForm;
