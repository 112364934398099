import React from "react";
import PropTypes from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";

class CardSection extends React.Component {
  static propTypes = {
    textColor: PropTypes.string,
    fontSize: PropTypes.string.isRequired,
  };

  render() {
    const options = {
      hidePostalCode: true,
      style: {
        base: {
          fontSize: this.props.fontSize,
          fontFamily: "'lato', sans serif",
          fontWeight: "400",
          color: this.props.textColor,
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#666",
          },
        },
        invalid: {
          iconColor: "#f3545b",
          color: "#e5424d",
          ":focus": {
            color: this.props.textColor,
          },
        },
      },
    };

    return <CardElement options={options} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    textColor: state.settings.backgroundColorComplement,
  };
};

export default connect(mapStateToProps)(CardSection);
