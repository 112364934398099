/*
 * For code we using the spelling 'color' and comments use the proper 'colour'
 * This is what happens when you let the yanks invent css.
 *
 */

import React from "react";
import PropTypes from "prop-types";
import raw from "raw.macro";

export default class CustomStyle extends React.Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.attachedToDOM = false;
  }

  getInlineStyle() {
    let css = raw("./CustomStyle.module.css");
    let settings = this.props.settings;

    for (let key in settings) {
      let value = settings[key] + ";";
      let reExp = "[$]" + key + ";";

      css = css.replace(new RegExp(reExp, "g"), value);
    }

    return css;
  }

  render() {
    if (!this.attachedToDOM) {
      const head = document.head || document.getElementsByTagName("head")[0];
      let node = document.createElement("style");
      node.innerHTML = this.getInlineStyle();
      head.appendChild(node);
      this.attachedToDOM = true;
    }

    return <div></div>;
  }
}
