import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styles from "./Product.css";
import Spinner from "components/Spinner";
import ProductTable from "components/ProductTable";
import Footer from "containers/Footer";
import {
  basketAddProduct,
  basketRemoveProduct,
  createReservation,
  showBasket,
  fetchProductsIfNeeded,
  fetchCollection,
} from "actions";
import { getBasketItemCount } from "selectors";

class ProductView extends React.Component {
  static propTypes = {
    collection: PropTypes.object,
    products: PropTypes.object.isRequired, // state.event.item
    basket: PropTypes.object.isRequired, // state.basket.items
    auth: PropTypes.object.isRequired, // state.auth
    loading: PropTypes.bool.isRequired, // state.reservation.isReserving
  };

  componentDidMount = () => {
    const { dispatch, collectionId } = this.props;
    if (collectionId) {
      dispatch(fetchProductsIfNeeded(collectionId));
      dispatch(fetchCollection(collectionId));
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.collectionId) {
      if (nextProps.collectionId !== this.props.collectionId) {
        const { dispatch, collectionId } = nextProps;
        dispatch(fetchProductsIfNeeded(collectionId));
        dispatch(fetchCollection(collectionId));
      }
    }
  }

  /**
   * Called when the basket info link is clicked
   *
   */
  handleBasketLinkClick = () => {
    if (this.props.basket.totals.itemCount === 0) {
      return;
    }
    this.props.dispatch(showBasket());
  };

  /**
   * Called when increment ticket is clicked
   *
   */
  handleIncrementClick = (product) => {
    const quantity = 1;
    this.props.dispatch(basketAddProduct(product, quantity));
  };

  /**
   * Called when decrement ticket is clicked
   *
   */
  handleDecrementClick = (product) => {
    const quantity = 1;
    this.props.dispatch(basketRemoveProduct(product, quantity));
  };

  /**
   * Called when the book button is clicked
   */
  handleBookClick = () => {
    if ("parentIFrame" in window) {
      window.parentIFrame.scrollToOffset(0, 0);
    }
    const { collections, collectionId, dispatch } = this.props;
    if (collections && collectionId) {
      const currentItem = collections.indexOf(collectionId);
      if (collections.length > currentItem + 1) {
        const nextCollectionId = collections[currentItem + 1];
        return dispatch(push(`/product-collection/${nextCollectionId}`));
      }
    }
    // make sure basket has items
    if (this.props.basketItemCount === 0) {
      return;
    }

    dispatch(createReservation());
  };

  render = () => {
    const { basket, products } = this.props;

    let productNode = () => {
      if (products.isFetching) {
        return <Spinner />;
      } else {
        return (
          <div className={styles.root}>
            <div>
              <div className={styles.collectionHeader}>
                <h2>{this.props.collection.name}</h2>
              </div>
              <div className={styles.root}>
                <ProductTable
                  products={this.props.products.items}
                  basket={basket.products.byId}
                  onIncrementClick={this.handleIncrementClick}
                  onDecrementClick={this.handleDecrementClick}
                />
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div className={styles.root}>
        {productNode()}
        <Footer
          loading={this.props.loading}
          onBookClick={this.handleBookClick}
        />
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const { settings, productsByCollection } = state;
  const basketItemCount = getBasketItemCount(state);
  const collectionId = ownProps.match.params.collectionId
    ? parseInt(ownProps.match.params.collectionId, 10)
    : null;
  const product = state.product.item;
  let products = {
    isFetching: false,
    items: [],
  };
  if (collectionId) {
    products = productsByCollection[collectionId] || {
      isFetching: false,
      items: [],
    };
  }

  if (product.id) {
    products.items.push(product);
  }
  return {
    basket: state.basket,
    basketItemCount: basketItemCount,
    ticketDiscount: state.discount.ticketDiscount,
    products,
    auth: state.auth,
    loading: state.reservation.isReserving,
    settings,
    collectionId,
    collection: state.collection.item,
    collections: state.collections,
  };
};

export default connect(mapStateToProps)(ProductView);
