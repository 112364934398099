import React from "react";
import styles from "./CheckboxGroup.module.css";

const CheckboxGroup = ({
  input,
  groupLabel,
  options,
  meta: { touched, error },
}) => {
  const { name, onChange, onBlur, onFocus } = input;
  const inputValue = input.value;

  const checkboxes = options.map(({ label, value }, index) => {
    const handleChange = (event) => {
      const arr = [...inputValue];
      if (event.target.checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }
      onBlur(arr);
      return onChange(arr);
    };
    const checked = inputValue.includes(value);
    return (
      <div
        className={`${styles.formRowCheck} ${styles.formRowInlineCheck}`}
        key={index}
      >
        <input
          type="checkbox"
          name={`${name}[${index}]`}
          value={value}
          checked={checked}
          onChange={handleChange}
          className={`${styles.formFieldCheck} ${styles.formFieldCheckInline}`}
          onFocus={onFocus}
        />
        <label
          key={`checkbox-${index}`}
          className={`${styles.formLabelCheck} ${styles.formLabelCheckInline}`}
        >
          {label}
        </label>
      </div>
    );
  });
  return (
    <div className={styles.checkboxGroup}>
      <label className={styles.checkBoxTitle}>{groupLabel}</label>
      <div className={styles.checkBoxBlock}>{checkboxes}</div>
      {touched && error && <span className={styles.formError}>{error}</span>}
    </div>
  );
};

export default CheckboxGroup;
