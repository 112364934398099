import React from "react";
import PropTypes from "prop-types";
import styles from "./Countdown.module.css";

export default class Countdown extends React.Component {
  static propTypes = {
    reservation: PropTypes.object.isRequired,
    onCountdownFinish: PropTypes.func.isRequired,
    debug: PropTypes.bool, // set to true to render a link which will fire the
  }; // onCountdownFinish callback

  static defaultProps = {
    debug: false,
  };

  state = {
    timer: null,
    display: "",
  };

  constructor(props) {
    super(props);
    this.periodic = null;
  }

  componentWillMount = () => {
    let seconds = this.props.reservation.holdingPeriod;
    this.setState({
      timer: seconds,
    });
    this.startTimer(seconds);
  };

  componentWillUnmount = () => {
    clearInterval(this.periodic);
  };

  /**
   * Update the countdown if the reservation is recreated
   *
   */
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.reservation.reference !== this.props.reservation.reference) {
      let seconds = nextProps.reservation.holdingPeriod;

      clearInterval(this.periodic);
      this.setState({
        timer: seconds,
      });

      this.startTimer(seconds);
    }
  };

  startTimer = (duration, display) => {
    let minutes = 0;
    let seconds = 0;

    this.periodic = setInterval(() => {
      minutes = parseInt(this.state.timer / 60, 10);
      seconds = parseInt(this.state.timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.setState({
        display: minutes + ":" + seconds,
        timer: this.state.timer - 1,
      });

      if (this.state.timer - 1 < 0) {
        clearInterval(this.periodic);
        this.props.onCountdownFinish();
      }
    }, 1000);
  };

  render = () => {
    return (
      <div className={`${styles.countdown} countdownCountdown`}>
        <p className={styles.countdownMessage}>Time to complete order:</p>
        <div className={styles.countdownNumber}>
          {this.state.display}
          {this.props.debug ? (
            <button onClick={this.props.onCountdownFinish}>Test expire</button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
}
