import React from "react";
import { connect } from "react-redux";
import styles from "./GiftAid.module.css";
import { push } from "connected-react-router";
import { addGiftAid, updateUser } from "actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import AddressForm from "components/AddressForm";
import { getUser } from "selectors";

export class GiftAidAddressView extends React.Component {
  handleAddGiftAidAddress = (values) => {
    return this.props.dispatch(updateUser(values));
  };
  handleSubmitSuccess = () => {
    this.props.dispatch(addGiftAid());
    this.props.dispatch(push(`/confirm`));
  };
  render() {
    const { user } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.backButton}>
          <Link to={"/confirm"}>
            <span className={styles.backButtonSpan}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>{" "}
            Cancel
          </Link>
        </div>
        <h3>Your Details</h3>
        <p>
          Gift Aid is reclaimed by the charity from the tax you pay for the
          current tax year. Your address is needed to identify you as a current
          UK taxpayer.
        </p>
        <p>
          Name:{" "}
          <span className={styles.giftAidAddressName}>
            {user.firstName} {user.lastName}
          </span>
        </p>

        <AddressForm
          initialValues={{
            address: {
              ...user.address,
              country: "GB",
            },
          }}
          onSubmit={this.handleAddGiftAidAddress}
          onSubmitSuccess={this.handleSubmitSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: getUser(state),
  };
};

export default connect(mapStateToProps)(GiftAidAddressView);
