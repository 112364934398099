import ReactGA from "react-ga";
import { getTicketsInBasket, getSeasonTicketsInBasket } from "selectors";
import {
  BASKET_ADD_TICKET,
  BASKET_ADD_SEASON_TICKET,
  LOGIN_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_ORDER_SUCCESS,
  UPDATE_ORDER_SUCCESS,
  BOOK_NOW_CLICK,
  RECEIVE_TICKETS,
  FETCH_EVENT_SETTINGS_SUCCESS,
} from "actions";

const options = {};

const trackPage = (page) => {
  ReactGA.set({
    page,
    ...options,
  });
  ReactGA.pageview(page);
};

let currentPage = "/";

const addImpression = (store, ticket) => {
  const event = store.event.item;
  const performance = store.selectedPerformance;

  ReactGA.plugin.execute("ec", "addImpression", {
    id: event.id,
    name: event.title,
    category: `${performance.startDate}/${performance.startTime}`,
    variant: ticket.description,
  });
};

const trackTicketImpressions = (store, response) => {
  response.data.map((ticket) => addImpression(store, ticket));
  ReactGA.event({
    category: "ticket table",
    action: "loaded",
    nonInteraction: true,
  });
};

const addProduct = (productItem) => {
  const { event, performance, ticket, quantity } = productItem;
  ReactGA.plugin.execute("ec", "addProduct", {
    id: event.id,
    name: event.title,
    price: ticket.total,
    variant: ticket.description,
    quantity: quantity,
    category: `${performance.startDate}/${performance.startTime}`,
  });
};

const trackAddRemoveItem = (actionType, action) => {
  addProduct(action);
  const eventLabel = actionType === "add" ? "addToBasket" : "removeFromBasket";
  ReactGA.plugin.execute("ec", "setAction", actionType);
  ReactGA.event({
    category: "ticket table",
    action: "click",
    label: eventLabel,
  });
};

const addBasket = (store) => {
  const tickets = getTicketsInBasket(store);
  const seasonTickets = getSeasonTicketsInBasket(store);
  tickets.map((item) => addProduct(item));
  seasonTickets.map((item) => addProduct(item));
};

const startCheckout = (store, category = "ticket table") => {
  addBasket(store);
  if (store.auth.loggedIn) {
    ReactGA.plugin.execute("ec", "setAction", "checkout", {
      step: 1,
      option: "loggedIn",
    });
  } else {
    ReactGA.plugin.execute("ec", "setAction", "checkout", {
      step: 1,
    });
  }
  ReactGA.event({
    category: "ticket table",
    action: "click",
    label: "bookNow",
  });
};

const trackCheckout = (store, step) => {
  addBasket(store);
  ReactGA.plugin.execute("ec", "setAction", "checkout", { step: step });
};

const trackLogin = (store) => {
  addBasket(store);
  ReactGA.plugin.execute("ec", "setAction", "checkout_option", {
    step: 1,
    option: "returningUser",
  });
  ReactGA.event({
    category: "auth",
    action: "click",
    label: "login",
  });
};

const trackRegister = (store) => {
  addBasket(store);
  ReactGA.plugin.execute("ec", "setAction", "checkout_option", {
    step: 1,
    option: "newUser",
  });
  ReactGA.event({
    category: "auth",
    action: "click",
    label: "register",
  });
};

const trackPurchase = (store, response) => {
  addBasket(store);
  if (response.data.invoice) {
    ReactGA.plugin.execute("ec", "setAction", "purchase", {
      id: response.data.invoice.reference,
      revenue: response.data.total,
      tax: response.data.tax,
      affiliation: store.settings.referrer,
    });
  }
};

export const googleAnalytics = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_EVENT_SETTINGS_SUCCESS: {
      const settings = action.response.data;
      if (settings.gaId) {
        ReactGA.initialize(settings.gaId, {
          debug: process.env.NODE_ENV === "development",
          titleCase: false,
          gaOptions: { cookieFlags: "max-age=7200;secure;samesite=none" },
        });

        ReactGA.plugin.require("ec");
      } else {
        ReactGA.initialize("UA-44258641-4", {
          debug: process.env.NODE_ENV === "development",
          titleCase: false,
          gaOptions: { cookieFlags: "max-age=7200;secure;samesite=none" },
        });
        ReactGA.plugin.require("ec");
      }
      break;
    }
    case "@@router/LOCATION_CHANGE": {
      const nextPage = `${action.payload.pathname}${action.payload.search}`;
      if (currentPage !== nextPage) {
        currentPage = nextPage;
        if (nextPage === "/confirm") {
          trackCheckout(store.getState(), 2);
        }
        trackPage(nextPage);
      }
      break;
    }
    case RECEIVE_TICKETS:
      trackTicketImpressions(store.getState(), action.response);
      break;
    case BASKET_ADD_TICKET:
    case BASKET_ADD_SEASON_TICKET:
      trackAddRemoveItem("add", action);
      break;
    // case BASKET_REMOVE_TICKET:
    // case BASKET_REMOVE_SEASON_TICKET:
    //   trackAddRemoveItem('remove', action)
    //   break;
    case BOOK_NOW_CLICK:
      startCheckout(store.getState());
      break;
    case LOGIN_USER_SUCCESS:
      trackLogin(store.getState());
      break;
    case CREATE_USER_SUCCESS:
      trackRegister(store.getState());
      break;
    case CREATE_ORDER_SUCCESS:
    case UPDATE_ORDER_SUCCESS:
      trackPurchase(store.getState(), action.response);
      break;
    default:
      break;
  }
  return next(action);
};
