import React from "react";
import styles from "./CountrySelect.module.css";

const CountrySelect = ({
  input,
  label,
  hideLabel,
  formClass,
  meta: { touched, error, value },
}) => (
  <div className={formClass}>
    <label className={styles.visibleLabel} hidden={hideLabel}>
      {label}
    </label>
    <div className={`${styles.outerWrap} ${styles[input.value.toLowerCase()]}`}>
      <select
        {...input}
        className={touched && error ? styles.formInputError : styles.formInput}
      >
        <option value="AU">Australia</option>
        <option value="AT">Austria</option>
        <option value="BE">Belgium</option>
        <option value="BR">Brazil</option>
        <option value="CA">Canada</option>
        <option value="CN">China</option>
        <option value="DK">Denmark</option>
        <option value="FI">Finland</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
        <option value="HK">Hong Kong</option>
        <option value="IE">Ireland</option>
        <option value="IT">Italy</option>
        <option value="JP">Japan</option>
        <option value="LU">Luxembourg</option>
        <option value="MX">Mexico</option>
        <option value="NL">Netherlands</option>
        <option value="NZ">New Zealand</option>
        <option value="NO">Norway</option>
        <option value="PT">Portugal</option>
        <option value="SG">Singapore</option>
        <option value="ES">Spain</option>
        <option value="SE">Sweden</option>
        <option value="CH">Switzerland</option>
        <option value="GB">United Kingdom</option>
        <option value="US">United States</option>
      </select>
    </div>
    {touched && error && <span className={styles.formError}>{error}</span>}
  </div>
);

export default CountrySelect;
