import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styles from "./Basket.module.css";
import { deleteLineItem } from "actions";
import BasketSummary from "components/BasketSummary";
import LaddaButton from "views/LaddaButton";
import { createReservation } from "actions";
import {
  getBasketItemCount,
  getBasketCurrency,
  getTicketsInBasket,
  getSeasonTicketsInBasket,
  getDonationAmount,
  getGiftAid,
  getProductsInBasket,
} from "selectors";

export class BasketView extends React.Component {
  static propTypes = {
    ticketDiscount: PropTypes.number,
    feeDiscount: PropTypes.number,
  };

  /**
   * Called when a reserved ticket is removed from the basket
   *
   */
  handleBasketItemRemove = (basketItem) => {
    this.props.dispatch(deleteLineItem(basketItem));
  };

  handleBookNowClick = () => {
    const { collections, basketItemCount, dispatch } = this.props;
    // make sure basket has items
    if (basketItemCount === 0) {
      return;
    }
    if ("parentIFrame" in window) {
      window.parentIFrame.scrollToOffset(0, 0);
    }
    if (collections) {
      const nextCollectionId = collections[0];
      return dispatch(push(`/product-collection/${nextCollectionId}`));
    }
    dispatch(createReservation());
  };

  handleShowDiscountClick = (e) => {
    e.preventDefault();
    const link = {
      pathname: "/add-discount",
      state: {
        modal: true,
        returnTo: "/",
      },
    };
    this.props.dispatch(push("/"));
    this.props.dispatch(push(link));
  };
  render() {
    return (
      <div className={styles.root}>
        <h2>Your Basket</h2>
        <BasketSummary
          ticketItems={this.props.ticketItems}
          seasonTicketItems={this.props.seasonTicketItems}
          productItems={this.props.productItems}
          currency={this.props.currency}
          handleBasketItemRemove={this.handleBasketItemRemove}
          ticketDiscount={this.props.ticketDiscount}
          feeDiscount={this.props.feeDiscount}
          donation={this.props.donation}
          onShowDiscountClick={this.handleShowDiscountClick}
          giftAid={this.props.enableGiftAid}
        />
        <div className={styles.bookButtonContainer}>
          <LaddaButton
            loading={false}
            onClick={this.handleBookNowClick}
            className={`${styles.bookButton} basketBookButton`}
            href="#"
          >
            Checkout
          </LaddaButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discount } = state;
  const ticketItems = getTicketsInBasket(state);
  const seasonTicketItems = getSeasonTicketsInBasket(state);
  const currency = getBasketCurrency(state);
  const basketItemCount = getBasketItemCount(state);
  return {
    ticketItems,
    seasonTicketItems,
    productItems: getProductsInBasket(state),
    collections: state.collections,
    currency,
    basketItemCount,
    ticketDiscount: discount.ticketDiscount,
    feeDiscount: discount.feeDiscount,
    donation: getDonationAmount(state),
    giftAid: getGiftAid(state),
  };
};

export default connect(mapStateToProps)(BasketView);
