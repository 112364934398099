import React from "react";
import { connect } from "react-redux";
import styles from "./GiftAid.module.css";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { getDonationAmount, getBasketCurrency } from "selectors";
import GiftAidForm from "components/GiftAidForm";

export class GiftAidView extends React.Component {
  handleAddGiftAid = (values) => {};
  handleSubmitSuccess = () => {
    this.props.dispatch(push(`/gift-aid-address`));
  };
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.backButton}>
          <Link to={"/confirm"}>
            <span className={styles.backButtonSpan}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>{" "}
            Cancel
          </Link>
        </div>
        <h2>Charity Gift Aid Declaration</h2>
        <GiftAidForm
          charityName={this.props.charityName}
          donationAmount={this.props.donationAmount}
          currency={this.props.currency}
          onSubmit={this.handleAddGiftAid}
          onSubmitSuccess={this.handleSubmitSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    donationAmount: getDonationAmount(state),
    currency: getBasketCurrency(state),
    charityName: state.settings.charityName,
  };
};
export default connect(mapStateToProps)(GiftAidView);
