import React from "react";
import PropTypes from "prop-types";
import styles from "./EmbeddedModal.module.css";
import close from "./close.png";
import { LinkButton } from "components/LinkButton";

export default class EmbeddedModal extends React.Component {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className={`${styles.root} embeddedModalRoot`}>
        <div className={styles.modalContent}>
          <div className={styles.modalClose}>
            <LinkButton
              onClick={this.props.onCloseClick}
              className={styles.closeView}
            >
              <img src={close} alt="close" />
            </LinkButton>
          </div>
          <div className={styles.modalMain}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
