import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  isSameDay,
  getPerformanceStartDate,
  getPerformanceEndDate,
  DATE_FORMATS,
} from "utils/DateFormatting";
import { LinkButton } from "components/LinkButton";
import EmbeddedModal from "components/EmbeddedModal";
import Calendar from "components/Calendar";
import styles from "./DateTimeBar.module.css";

export default class DateTimeBar extends React.Component {
  static propTypes = {
    performances: PropTypes.array.isRequired,
    selectedPerformance: PropTypes.object.isRequired,
    onDateClick: PropTypes.func.isRequired,
    onTimeClick: PropTypes.func.isRequired,
    onBookingLinkClick: PropTypes.func.isRequired,
    onBasketLinkClick: PropTypes.func.isRequired,
    booking: PropTypes.bool.isRequired,
    bookingFetched: PropTypes.bool.isRequired,
    ticketsByPerformance: PropTypes.object,
    basketItemCount: PropTypes.number,
    showCalendar: PropTypes.bool.isRequired,
    onCalendarOpenClick: PropTypes.func.isRequired,
    onCalendarCloseClick: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
  };

  state = {
    groupedPerformances: null, // startDateStr -> [Peformance] map
  };

  componentWillMount = () => {
    let performancesGrouped = this.groupPerformancesByDay(
      this.props.performances
    );
    this.setState({
      groupedPerformances: performancesGrouped,
    });
  };

  /**
   * Called when a date is clicked on the navbar, there is a separate
   * handler for when a date is clicked in the calendar
   *
   */
  handleDateClick = (perf) => {
    return (evt) => {
      this.props.onDateClick([perf], evt);
    };
  };

  /**
   * Called when a time is clicked
   *
   */
  handleTimeClick = (performance) => {
    return (evt) => {
      this.props.onTimeClick(performance, evt);
    };
  };

  /**
   * Called when a day is clicked in the calendar
   *
   */
  handleCalendarDayClick = (date, evt) => {
    // this finds the performance that the date is mapped to
    let performances = this.props.performances.filter((perf) => {
      let performanceDate = getPerformanceStartDate(perf).toDate();
      if (isSameDay(performanceDate, date)) {
        return true;
      }
      return false;
    });

    if (!performances) {
      return false;
    }

    this.props.onCalendarCloseClick(evt);
    this.props.onDateClick(performances, evt);
  };

  /**
   * Given a list of performances, this will return a hash(object) where the
   * key is the startDate and the value is a list of performances
   *
   */
  groupPerformancesByDay = (performances) => {
    let grouping = {};
    for (let perf of performances) {
      if (!grouping.hasOwnProperty(perf.startDate)) {
        grouping[perf.startDate] = [];
      }

      grouping[perf.startDate].push(perf);
    }
    return grouping;
  };

  /**
   * Accepts a moment date object and returns a formatted date
   *
   * @param {moment} date the date to format
   *
   */
  formatDate = (date) => {
    let d = new Date();
    if (d.getFullYear() !== date.year()) {
      return date.format(DATE_FORMATS.NAV_DATE_FULL);
    } else {
      return date.format(DATE_FORMATS.NAV_DATE);
    }
  };

  handleShowBasket = (evt) => {
    evt.preventDefault();
    if (this.props.basketItemCount > 0) {
      this.props.onBasketLinkClick(evt);
    }
  };

  /**
   * Returns the HTML for the date tabs
   *
   */
  renderDateBar = () => {
    let dateNodes = [];
    let keys = Object.keys(this.state.groupedPerformances);

    if (keys.length > 1) {
      dateNodes = this.getDateBarWithCalendar();
    } else {
      dateNodes = this.getNormalDateBar();
    }

    let itemsNode;
    let basketStyle = `${styles.showBasketEmpty} dateTimeBarShowBasketEmpty`;
    if (this.props.basketItemCount > 0) {
      itemsNode = (
        <span
          className={`${styles.basketNotification} dateTimeBarBasketNotification`}
        >
          {this.props.basketItemCount}
        </span>
      );
      basketStyle = `${styles.showBasket} dateTimeBarShowBasket`;
    }
    let basketNode = (
      <span key={"basket"} className={basketStyle}>
        <LinkButton onClick={this.handleShowBasket}>
          <span className={styles.basketIcon}>
            <FontAwesomeIcon icon="shopping-cart" />
          </span>
          {itemsNode}
        </LinkButton>
      </span>
    );
    dateNodes.push(basketNode);

    if (this.props.bookingFetched && this.props.booking) {
      let bookingNode = (
        <span
          key={"booking"}
          className={`${styles.bookingContainer} dateTimeBarBookingContainer`}
        >
          <LinkButton onClick={this.props.onBookingLinkClick}>
            <span>
              <FontAwesomeIcon icon="info-circle" />
            </span>{" "}
            <p>Info</p>
          </LinkButton>
        </span>
      );
      dateNodes.push(bookingNode);
    }

    return (
      <div className={`${styles.dateBarRoot} dateTimeBarDateBarRoot}`}>
        {dateNodes}
      </div>
    );
  };

  /**
   * If there are 3 or more date, this will generate the html for the date bar
   *
   */
  getDateBarWithCalendar = () => {
    let selectedDate = this.props.selectedDate;
    let firstPerformance = this.state.groupedPerformances[selectedDate][0];
    let date = getPerformanceStartDate(firstPerformance);
    let className = `${styles.dateSelected} dateTimeBarDateSelected`;
    let firstNode = (
      <span key={"first"} className={className}>
        <LinkButton onClick={this.handleDateClick(firstPerformance)}>
          {this.formatDate(date)}
        </LinkButton>
      </span>
    );

    let calendarNode = (
      <div
        key={"second"}
        className={`${styles.changeDate} dateTimeBarTimeChangeDate`}
      >
        <span className={`${styles.changeDate} dateTimeBarTimeChangeDate`}>
          <LinkButton onClick={this.props.onCalendarOpenClick}>
            <FontAwesomeIcon
              icon="calendar"
              className={styles.changeDateIcon}
            />{" "}
            Select Date
          </LinkButton>
        </span>
      </div>
    );

    let calendarModal = (
      <EmbeddedModal onCloseClick={this.props.onCalendarCloseClick} key="third">
        <Calendar
          day={date.toDate()}
          onDayClick={this.handleCalendarDayClick}
          performances={this.props.performances}
          currency={this.props.currency}
        />
      </EmbeddedModal>
    );

    let calendarModalContainer = this.props.showCalendar ? calendarModal : null;

    let dateNodes = [firstNode, calendarNode, calendarModalContainer];

    return dateNodes;
  };

  /**
   * If there are less than 3 dates, this will generate the html for the date bar
   *
   */
  getNormalDateBar = () => {
    let dateNodes = [];
    let selectedGroupKey = this.props.selectedDate;
    for (let k in this.state.groupedPerformances) {
      let performances = this.state.groupedPerformances[k];
      let date = getPerformanceStartDate(performances[0]);
      let className =
        selectedGroupKey === k
          ? `${styles.dateSelected} dateTimeBarDateSelected`
          : `${styles.date} dateTimeBarDate`;
      let node = (
        <span key={k} className={className}>
          <LinkButton onClick={this.handleDateClick(performances[0])}>
            {this.formatDate(date)}
          </LinkButton>
        </span>
      );
      dateNodes.push(node);
    }

    return dateNodes;
  };

  /**
   * This generates the html for the time bar
   *
   */
  renderTimeBar = (performances) => {
    let timeNodes = [];
    const orderedPerformances = performances.sort((p1, p2) =>
      getPerformanceStartDate(p1).isBefore(getPerformanceStartDate(p2)) ? -1 : 1
    );
    for (let performance of orderedPerformances) {
      let isSoldOut =
        performance.availability !== null && performance.availability === 0;
      let className = isSoldOut
        ? `${styles.timeSoldOut} dateTimeBarTimeSoldOut`
        : `${styles.time} dateTimeBarTime`;
      if (
        this.props.selectedPerformance &&
        performance.id === this.props.selectedPerformance.id
      ) {
        className = `${styles.timeSelected} dateTimeBarTimeSelected`;
      }
      let date = getPerformanceStartDate(performance);
      let endDate;
      if (performance.endTime) {
        endDate = getPerformanceEndDate(performance);
        className = `${className} ${styles.endTime}`;
      }

      let style = performance.color
        ? { backgroundColor: performance.color }
        : {};
      let node = (
        <LinkButton
          key={performance.id}
          onClick={this.handleTimeClick(performance)}
          className={`${className} DateTimeBar__timeSelected`}
          style={style}
        >
          <span key={performance.id}>
            {date.format(DATE_FORMATS.NAV_TIME)}
            {performance.endTime &&
              ` - ${endDate.format(DATE_FORMATS.NAV_TIME)}`}
          </span>
        </LinkButton>
      );
      timeNodes.push(node);
    }

    return <div className={styles.timeBarRoot}>{timeNodes}</div>;
  };

  render = () => {
    let visiblePerformances =
      this.state.groupedPerformances[this.props.selectedDate] ?? [];
    return (
      <div>
        {this.renderDateBar()}
        {this.renderTimeBar(visiblePerformances)}
      </div>
    );
  };
}
