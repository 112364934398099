import React from "react";
import PropTypes from "prop-types";
import * as Ladda from "ladda";
import styles from "./LaddaButton.module.css";
import "ladda/dist/ladda-themeless.min.css";

export default class LaddaButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    spinnerColor: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    disabled: false,
    spinnerColor: "#ffffff",
  };

  constructor(props) {
    super(props);
    this.laddaButton = null;
  }

  componentDidMount() {
    this.laddaButton = Ladda.create(this.refs.button);
  }

  componentWillUnmount() {
    if (this.laddaButton.remove) {
      this.laddaButton.remove();
    }
  }

  componentDidUpdate() {
    if (!this.laddaButton) {
      return;
    }

    if (this.props.loading && !this.laddaButton.isLoading()) {
      this.laddaButton.start();
      return;
    }

    if (!this.props.loading && this.laddaButton.isLoading()) {
      this.laddaButton.stop();
      return;
    }
  }

  handleClick = (evt) => {
    if (!this.props.disabled) {
      this.props.onClick(evt);
    }
  };

  render() {
    let props = {
      className: "ladda-button " + this.props.className + " " + styles.root,
      "data-style": "zoom-in",
      "data-spinner-color": this.props.spinnerColor,
    };

    return (
      <button ref="button" {...props} onClick={this.handleClick}>
        <span className="ladda-label">{this.props.children}</span>
      </button>
    );
  }
}
