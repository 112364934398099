import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginForm from "components/LoginForm";
import { loginAndFetchCards } from "actions";
import styles from "./Login.module.css";
import { push } from "connected-react-router";
import { getBasketIsValid } from "selectors";

class LoginView extends React.Component {
  submit = (values) => {
    return this.props.dispatch(loginAndFetchCards(values));
  };
  handLoginSuccess = () => {
    if (this.props.includeAddress && !this.props.user.item.address) {
      return this.props.dispatch(push("/address"));
    } else if (this.props.customFormId) {
      this.props.dispatch(push(`/form/${this.props.customFormId}`));
    } else if (this.props.basketIsValid) {
      this.props.dispatch(push(`/confirm`));
    } else {
      this.props.dispatch(push("/"));
    }
  };

  render() {
    return (
      <div className={styles.root}>
        <div className={`${styles.backButton} loginBackButton`}>
          <Link to={"/register"}>
            <span className={styles.backButtonSpan}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back to register
          </Link>
        </div>
        <div className={styles.loginContainer}>
          <div className={styles.loginHeader}>
            <h2>Welcome back</h2>
          </div>
          <LoginForm
            onSubmit={this.submit}
            onSubmitSuccess={this.handLoginSuccess}
          />
          <Link
            to={"/forgot-password"}
            className={`${styles.forgotPassword} loginForgotPassword`}
          >
            Forgot password?
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    includeAddress: state.settings.includeAddress ? true : false,
    customFormId: state.settings.customFormId,
    basketIsValid: getBasketIsValid(state),
  };
};
export default connect(mapStateToProps)(LoginView);
