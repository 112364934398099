import React from "react";
import { FormSection, Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FormField from "../FormField";
import LaddaButton from "../LaddaButton";
import CountrySelect from "../CountrySelect";
import styles from "./AddressForm.module.css";

const required = (value) => (value ? undefined : "Required");

let AddressForm = (
  { error, handleSubmit, pristine, submitting, country },
  props
) => {
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <FormSection name="address">
        {error && <div className={styles.formError}>{error}</div>}
        <div>
          <Field
            name="street"
            component={FormField}
            type="text"
            label="Street Address"
            hideLabel={true}
            autoComplete="street-address"
            validate={[required]}
          />
        </div>
        <div>
          <Field
            name="region"
            component={FormField}
            type="text"
            label="City"
            hideLabel={true}
            autoComplete="address-level2"
            validate={[required]}
          />
        </div>
        {country === "US" && (
          <div className={styles.state}>
            <Field
              name="county"
              component={FormField}
              type="text"
              label="State"
              hideLabel={true}
              autoComplete="address-level1"
              validate={[required]}
            />
          </div>
        )}
        <div className={country === "US" ? styles.shortPostcode : ""}>
          <Field
            name="postcode"
            component={FormField}
            type="text"
            label={country === "US" ? "ZIP" : "Postcode"}
            hideLabel={true}
            autoComplete="postal-code"
            validate={[required]}
          />
        </div>
        <div>
          <Field
            name="country"
            component={CountrySelect}
            label="Country"
            hideLabel={true}
            autoComplete="country"
          />
        </div>
        <div>
          <LaddaButton
            type="submit"
            disabled={submitting}
            className={`${styles.reviewButton} addressFormReviewButton`}
            loading={submitting}
            onClick={handleSubmit}
          >
            Review Order
          </LaddaButton>
        </div>
      </FormSection>
    </form>
  );
};

AddressForm = reduxForm({
  form: "addressForm",
})(AddressForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector("addressForm");
  const country = selector(state, "address.country");
  return {
    country,
  };
};

export default connect(mapStateToProps)(AddressForm);
