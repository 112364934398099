import React from "react";
import styles from "./CheckboxGroup.module.css";

const RadioField = ({
  input,
  groupLabel,
  options,
  meta: { touched, error },
}) => {
  const { onChange, onBlur, onFocus } = input;

  const radios = options.map(({ label, value }, index) => {
    return (
      <div
        className={`${styles.formRowCheck} ${styles.formRowInlineCheck}`}
        key={index}
      >
        <input
          type="radio"
          name={input.name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={`${styles.formFieldCheck} ${styles.formFieldCheckInline}`}
        />
        <label
          key={`radio-${index}`}
          className={`${styles.formLabelCheck} ${styles.formLabelCheckInline}`}
        >
          {label}
        </label>
      </div>
    );
  });
  return (
    <div className={styles.checkboxGroup}>
      <label className={styles.checkBoxTitle}>{groupLabel}</label>
      <div className={styles.checkBoxBlock}>{radios}</div>
      {touched && error && <span className={styles.formError}>{error}</span>}
    </div>
  );
};

export default RadioField;
