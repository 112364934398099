import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegisterForm from "components/RegisterForm";
import { createUser } from "actions";
import styles from "./Register.module.css";
import { push } from "connected-react-router";

class RegisterView extends React.Component {
  handleSubmit = (values) => {
    return this.props.dispatch(createUser(values));
  };

  handleRegisterSuccess = () => {
    if (this.props.includeAddress) {
      return this.props.dispatch(push("/address"));
    } else if (this.props.customFormId) {
      this.props.dispatch(push(`/form/${this.props.customFormId}`));
    } else {
      this.props.dispatch(push(`/confirm`));
    }
  };

  render() {
    return (
      <div className={styles.root}>
        <div className={`${styles.backButton} registerBackButton`}>
          <Link to={"/"}>
            <span className={`${styles.backButton} registerBackButton`}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back
          </Link>
        </div>
        <div>
          <div className={styles.registerHeader}>
            <h2>Account Details</h2>
            <div className={styles.loginBox}>
              <p>Ordered here before?</p>
              <Link
                to={"/login"}
                className={`${styles.loginButton} registerLoginButton`}
              >
                Login
              </Link>
            </div>
          </div>
          <RegisterForm
            onSubmit={this.handleSubmit}
            optInText={this.props.optInText}
            onSubmitSuccess={this.handleRegisterSuccess}
            includePhone={this.props.includePhone}
            initialValues={{
              optInText: this.props.optInText,
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    optInText: state.settings.optInText,
    includeAddress: state.settings.includeAddress ? true : false,
    includePhone: state.settings.includePhone ? true : false,
    customFormId: state.settings.customFormId,
  };
};
export default connect(mapStateToProps)(RegisterView);
