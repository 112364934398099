import { combineReducers } from "redux";
import { connectRouter, LOCATION_CHANGE } from "connected-react-router";
import CustomColors from "utils/Colors";
import { reducer as formReducer } from "redux-form";
import {
  FETCH_EVENT_START,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAIL,
  INVALIDATE_PERFORMANCE,
  REQUEST_TICKETS,
  RECEIVE_TICKETS,
  SELECT_PERFORMANCE,
  SELECT_DATE,
  FETCH_PERFORMANCES_START,
  FETCH_PERFORMANCES_FAIL,
  FETCH_PERFORMANCES_SUCCESS,
  FETCH_ALL_PERFORMANCES_SUCCESS,
  BASKET_ADD_TICKET,
  BASKET_ADD_SEASON_TICKET,
  BASKET_ADD_PRODUCT,
  BASKET_REMOVE_TICKET,
  BASKET_REMOVE_SEASON_TICKET,
  BASKET_REMOVE_LINE_ITEM,
  BASKET_REMOVE_PRODUCT,
  CREATE_RESERVATION_START,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAIL,
  DELETE_RESERVATION,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  CREATE_ORDER_START,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  EXPIRED_RESERVATION_START,
  CREATE_CARD_AND_CHECKOUT_START,
  CREATE_CARD_AND_CHECKOUT_SUCCESS,
  CREATE_CARD_AND_CHECKOUT_FAIL,
  SEND_FORGOT_PASSWORD_EMAIL_START,
  SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
  SEND_FORGOT_PASSWORD_EMAIL_FAIL,
  APPLY_DISCOUNT_CODE_START,
  APPLY_DISCOUNT_CODE_SUCCESS,
  APPLY_DISCOUNT_CODE_FAIL,
  OPEN_CALENDAR,
  CLOSE_CALENDAR,
  CREATE_SEATING_RESERVATION_TOKEN_SUCCESS,
  REQUEST_SEASON_TICKETS,
  RECEIVE_SEASON_TICKETS,
  UPDATE_RESERVATION_START,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAILURE,
  ADD_DONATION,
  REMOVE_DONATION,
  ADD_GIFT_AID,
  REMOVE_GIFT_AID,
  SHOW_OTHER_DONATION,
  HIDE_OTHER_DONATION,
  REDIRECT_START,
  FETCH_EVENT_SETTINGS_START,
  FETCH_EVENT_SETTINGS_SUCCESS,
  FETCH_EVENT_SETTINGS_FAIL,
  FETCH_FORM_START,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_FAIL,
  JOIN_WAITING_LIST_START,
  JOIN_WAITING_LIST_SUCCESS,
  JOIN_WAITING_LIST_FAIL,
  FETCH_PAST_ORDERS_START,
  FETCH_PAST_ORDERS_SUCCESS,
  FETCH_PAST_ORDERS_FAIL,
  SELECT_PAST_ORDER,
  CANCEL_SELECT_ORDER,
  CANCEL_TICKET_START,
  CANCEL_TICKET_SUCCESS,
  CANCEL_TICKET_FAIL,
  SET_BASKET_VALID,
  SET_BASKET_INVALID,
  UPDATE_ORDER_SUCCESS,
  FETCH_PRODUCT_START,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAIL,
  FETCH_COLLECTION_START,
  FETCH_COLLECTION_SUCCESS,
  FETCH_COLLECTION_FAIL,
  INVALIDATE_COLLECTION,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  FETCH_STRIPE_SETTINGS_SUCCESS,
} from "actions";

export const initialState = {
  currency: "GBP",
  auth: {
    loggedIn: false,
    isLoggingIn: false,
    apiKey: null,
    authorization: null,
    loginError: null,
    isAuthedViaCookie: null,
  },
  user: {
    createError: null,
    isFetching: false,
    isFetched: false,
    item: {},
  },
  forgotPassword: {
    isSending: false,
    isSent: false,
    sendError: null,
  },
  event: {
    isFetched: false,
    isFetching: false,
    error: false,
    item: {},
  },
  performances: {
    isFetching: false,
    isFetched: false,
    pages: 1,
    items: [],
  },
  capacity: {
    hasGroups: false,
    items: {},
  },
  product: {
    isFetched: false,
    isFetching: false,
    error: false,
    item: {},
  },
  collection: {
    isFetched: true,
    isFetching: false,
    error: false,
    item: {},
  },
  collections: null,
  booking: {
    isFetched: false,
    isFetching: false,
    item: null,
  },
  customForm: {
    isFetched: false,
    isLoading: false,
    item: {},
  },
  reservation: {
    isReserving: false,
    isReserved: false,
    item: {},
    sessionId: null,
  },
  order: {
    isCreating: false,
    isCreated: false,
    createError: null,
    item: {},
  },
  flow: {
    isRegisteringWithCard: false,
    isRegisteringAndCheckingOut: false,
    isAuthenticatingUser: false,
    isCreatingCardAndCheckingOut: false,
    isRedirectingToListings: false,
  },
  settings: {
    backgroundColor: "#ffffff",
    mainColor: "#32898f",
    optInText: "Add me to the mailing list",
    enableDonations: false,
    donationOptions: [2.5, 5, 10, 15],
    showWaitingList: false,
    showPastOrders: false,
    noOrphanSeats: false,
    enableGiftAid: false,
    charityName: null,
    donationText: null,
    lastDate: "2030-01-01T00:00:00.000Z",
    firstDate: null,
  },
  discount: {
    isApplying: false,
    discountError: null,
    items: [],
    ticketDiscount: 0,
    feeDiscount: 0,
  },
  showCalendar: true,
  selectedPerformance: {},
  selectedDate: null,
  waitingList: {
    isSending: false,
    hasJoined: false,
    sendError: null,
  },
  pastOrders: {
    pastOrdersByPage: {},
    selectedOrder: null,
  },
};

const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: true,
        authorization: action.response.data.accessToken,
        loginError: null,
      });

    case LOGIN_USER_START:
      return Object.assign({}, state, {
        isLoggingIn: true,
        loginError: null,
      });

    case LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoggingIn: false,
        loggedIn: true,
        authorization: action.response.data.accessToken,
        loginError: null,
      });

    case LOGIN_USER_FAIL:
      return Object.assign({}, state, {
        isLoggingIn: false,
        loggedIn: false,
        loginError: action.response,
      });

    case LOGOUT_USER:
      return Object.assign({}, state, {
        isLoggingIn: false,
        loggedIn: false,
        loginError: null,
        authorization: null,
        isAuthedViaCookie: null,
      });

    case LOCATION_CHANGE:
      return Object.assign({}, state, {
        loginError: null,
      });

    default:
      return state;
  }
};

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case FETCH_USER_START:
      return Object.assign({}, state, {
        item: {},
        isFetching: true,
        isFetched: false,
      });

    case CREATE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case FETCH_USER_SUCCESS:
      return Object.assign({}, state, {
        item: action.response.data,
        isFetching: false,
        isFetched: true,
        createError: null,
      });

    case CREATE_USER_FAIL:
    case UPDATE_USER_FAIL:
    case FETCH_USER_FAIL:
      return Object.assign({}, state, {
        createError: action.response,
        isFetching: false,
      });

    case LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        item: action.response.data,
        isFetched: true,
        isFetching: false,
      });

    case LOGOUT_USER:
      return Object.assign({}, state, {
        createError: null,
        isFetching: false,
        isFetched: false,
        item: {},
      });

    case LOCATION_CHANGE:
      return Object.assign({}, state, {
        createError: null,
      });

    default:
      return state;
  }
};

const event = (state = initialState.event, action) => {
  switch (action.type) {
    case FETCH_EVENT_START:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case FETCH_EVENT_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        error: true,
      });

    case FETCH_EVENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      });

    default:
      return state;
  }
};

const selectedPerformance = (state = {}, action) => {
  switch (action.type) {
    case SELECT_PERFORMANCE:
      return action.performance;
    case SELECT_DATE:
      return {};
    default:
      return state;
  }
};

const selectedDate = (state = null, action) => {
  switch (action.type) {
    case SELECT_DATE:
      return action.date;
    case SELECT_PERFORMANCE:
      return action.performance.startDate;
    default:
      return state;
  }
};

const parseTickets = (tickets) => {
  const descriptions = [];
  let i = {};
  const newTickets = tickets.reduce((tickets, ticket) => {
    if (ticket.discounts) {
      ticket.discounts.forEach((discount) => {
        let desc = discount.description
          ? `${ticket.description} - ${discount.description}`
          : ticket.description;
        let key = `${ticket.categoryId}:${desc}`;
        if (descriptions.includes(key)) {
          i[ticket.categoryId] = (i[ticket.categoryId] || 0) + 1;
          desc = `${desc}-${i[ticket.categoryId]}`;
          key = `${key}-${i[ticket.categoryId]}`;
        }
        descriptions.push(key);
        tickets.push({
          ...ticket,
          total: ticket.total - discount.totalTicketDiscount,
          totalExFees: ticket.totalExFees - discount.totalTicketDiscount,
          totalTicketDiscount: discount.totalTicketDiscount,
          id: `${ticket.id}:${discount.discountCode}`,
          ticketId: ticket.id,
          description: desc,
          discountCode: discount.discountCode,
          inventoryLeft: discount.inventoryLeft,
        });
      });
    }
    tickets.push(ticket);
    return tickets;
  }, []);
  return newTickets;
};

const tickets = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case INVALIDATE_PERFORMANCE:
      return {
        ...state,
        didInvalidate: true,
      };
    case REQUEST_TICKETS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: true,
      };
    case RECEIVE_TICKETS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: parseTickets(action.response.data),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const ticketsByPerformance = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PERFORMANCE:
    case REQUEST_TICKETS:
    case RECEIVE_TICKETS:
      return {
        ...state,
        [action.performanceId]: tickets(state[action.performanceId], action),
      };
    default:
      return state;
  }
};

const seasonTicketsByPerformance = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PERFORMANCE:
    case REQUEST_SEASON_TICKETS:
    case RECEIVE_SEASON_TICKETS:
      return {
        ...state,
        [action.performanceId]: seasonTickets(
          state[action.performanceId],
          action
        ),
      };
    default:
      return state;
  }
};

const seasonTickets = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case INVALIDATE_PERFORMANCE:
      return {
        ...state,
        didInvalidate: true,
      };
    case REQUEST_SEASON_TICKETS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: true,
      };
    case RECEIVE_SEASON_TICKETS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.response.data,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const product = (state = initialState.product, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_START:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case FETCH_PRODUCT_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        error: true,
      });

    case FETCH_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      });

    default:
      return state;
  }
};

const productsReducer = (
  state = {
    byId: {},
    allIds: [],
  },
  action
) => {
  switch (action.type) {
    case BASKET_ADD_PRODUCT:
      return addToBasket(state, action);
    case BASKET_REMOVE_PRODUCT:
      return removeFromBasket(state, action);
    case BASKET_REMOVE_LINE_ITEM:
      return removeLineItem(state, action);
    case LOGOUT_USER:
      return {
        byId: {},
        allIds: [],
      };
    default:
      return state;
  }
};

const addTicketToBasket = (
  state = {
    quantity: 0,
    seats: [],
  },
  action
) => {
  return {
    ...state,
    quantity: state.quantity + action.quantity,
    ticket: action.ticket,
    seats: action.seat ? state.seats.concat(action.seat) : state.seats,
    performance: action.performance,
    event: action.event,
  };
};

const removeSeat = (existingSeats, seat) => {
  const index = existingSeats.indexOf(seat);
  if (index !== -1) {
    existingSeats.splice(index, 1);
  }
  return existingSeats.slice();
};
const removeTicketFromBasket = (state, action) => {
  const existingTicket = state[action.ticket.id];
  if (existingTicket.quantity - action.quantity <= 0) {
    delete state[action.ticket.id];
    return state;
  }
  return {
    ...state,
    [action.ticket.id]: {
      ...existingTicket,
      quantity: existingTicket.quantity - action.quantity,
      seats: removeSeat(existingTicket.seats, action.seat),
    },
  };
};

const addToBasket = (state, action) => {
  const { ticket } = action;
  const { byId, allIds } = state;
  const existingTicket = byId[ticket.id];
  return {
    byId: {
      ...byId,
      [ticket.id]: addTicketToBasket(existingTicket, action),
    },
    allIds: [...allIds, ...(existingTicket ? [] : [ticket.id])],
  };
};

const removeFromBasket = (state, action) => {
  const { ticket } = action;
  const { byId, allIds } = state;
  const existingTicket = byId[ticket.id];
  return {
    byId: removeTicketFromBasket(byId, action),
    allIds:
      existingTicket && existingTicket.quantity - action.quantity <= 0
        ? allIds.filter((ticketId) => ticketId !== ticket.id)
        : allIds,
  };
};
const removeLineItem = (state, action) => {
  const { byId, allIds } = state;
  const ticketId = action.item.ticket.id;
  let res = Object.assign({}, byId);
  delete res[ticketId];
  return {
    byId: res,
    allIds: allIds.filter((id) => id !== ticketId),
  };
};

const seasonTicketsReducer = (
  state = {
    byId: {},
    allIds: [],
  },
  action
) => {
  switch (action.type) {
    case BASKET_ADD_SEASON_TICKET:
      return addToBasket(state, action);
    case BASKET_REMOVE_SEASON_TICKET:
      return removeFromBasket(state, action);
    case BASKET_REMOVE_LINE_ITEM:
      return removeLineItem(state, action);
    case LOGOUT_USER:
    case CREATE_RESERVATION_FAIL:
      return {
        byId: {},
        allIds: [],
      };
    default:
      return state;
  }
};

const ticketsReducer = (
  state = {
    byId: {},
    allIds: [],
  },
  action
) => {
  switch (action.type) {
    case BASKET_ADD_TICKET:
      return addToBasket(state, action);
    case BASKET_REMOVE_TICKET:
      return removeFromBasket(state, action);
    case BASKET_REMOVE_LINE_ITEM:
      return removeLineItem(state, action);
    case LOGOUT_USER:
    case CREATE_RESERVATION_FAIL:
      return {
        byId: {},
        allIds: [],
      };
    default:
      return state;
  }
};
const validSelection = (state = true, action) => {
  switch (action.type) {
    case SET_BASKET_VALID:
      return true;
    case SET_BASKET_INVALID:
      return false;
    default:
      return state;
  }
};
const basket = combineReducers({
  tickets: ticketsReducer,
  seasonTickets: seasonTicketsReducer,
  products: productsReducer,
  validSelection: validSelection,
});

const collection = (state = initialState.collection, action) => {
  switch (action.type) {
    case FETCH_COLLECTION_START:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case FETCH_COLLECTION_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        error: true,
      });

    case FETCH_COLLECTION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      });

    default:
      return state;
  }
};

const collections = (state = initialState.collections, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const products = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case INVALIDATE_COLLECTION:
      return {
        ...state,
        didInvalidate: true,
      };
    case REQUEST_PRODUCTS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: true,
      };
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.response.data,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const productsByCollection = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COLLECTION:
    case REQUEST_PRODUCTS:
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        [action.collectionId]: products(state[action.collectionId], action),
      };
    default:
      return state;
  }
};

const reservation = (state = initialState.reservation, action) => {
  switch (action.type) {
    case CREATE_RESERVATION_START:
    case UPDATE_RESERVATION_START:
      return Object.assign({}, state, {
        isReserving: true,
      });

    case CREATE_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_SUCCESS:
      return Object.assign({}, state, {
        isReserved: true,
        isReserving: false,
        error: false,
        basketHash: action.basketHash,
        item: action.response.data,
        sessionId: action.response.data.sessionId,
      });

    case CREATE_RESERVATION_FAIL:
    case UPDATE_RESERVATION_FAILURE:
      return Object.assign({}, state, {
        isReserving: false,
        error: true,
        item: {},
        isReserved: false,
      });

    case EXPIRED_RESERVATION_START:
      return Object.assign({}, state, {
        isReserving: false,
        error: false,
        item: {},
        isReserved: false,
      });

    case LOGOUT_USER:
      return Object.assign({}, state, {
        isReserved: false,
        item: {},
      });

    case DELETE_RESERVATION:
      return Object.assign({}, state, {
        isReserved: false,
        isReserving: false,
        item: {},
      });

    default:
      return state;
  }
};

const order = (state = initialState.order, action) => {
  switch (action.type) {
    case CREATE_ORDER_START:
      return Object.assign({}, state, {
        isCreating: true,
        isCreated: false,
      });

    case CREATE_ORDER_FAIL:
      return Object.assign({}, state, {
        isCreated: false,
        isCreating: false,
        createError: action.error,
      });

    case CREATE_ORDER_SUCCESS:
    case UPDATE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        isCreated: true,
        item: action.response.data,
        createError: null,
      });

    case LOGOUT_USER:
      return Object.assign({}, state, {
        isCreated: false,
        item: {},
        createError: null,
      });

    default:
      return state;
  }
};

const flow = (state = initialState.flow, action) => {
  switch (action.type) {
    // -- user logged in but no card attached
    case CREATE_CARD_AND_CHECKOUT_START:
      return Object.assign({}, state, {
        isCreatingCardAndCheckingOut: true,
      });

    case CREATE_CARD_AND_CHECKOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreatingCardAndCheckingOut: false,
      });

    case CREATE_CARD_AND_CHECKOUT_FAIL:
      return Object.assign({}, state, {
        isCreatingCardAndCheckingOut: false,
      });
    // redirect to listing page
    case REDIRECT_START:
      return Object.assign({}, state, {
        isRedirectingToListings: true,
      });
    default:
      return state;
  }
};

const settings = (state = initialState.settings, action) => {
  switch (action.type) {
    case FETCH_EVENT_SETTINGS_START:
      return {
        ...state,
        isFetched: false,
      };
    case FETCH_EVENT_SETTINGS_SUCCESS:
      const customColors = new CustomColors(action.response.data);
      const settingsData = customColors.processColorSettings();
      return {
        ...state,
        ...settingsData,
        isFetched: true,
      };
    case FETCH_EVENT_SETTINGS_FAIL:
      return {
        ...state,
        isFetched: true,
      };
    case FETCH_STRIPE_SETTINGS_SUCCESS:
      return {
        ...state,
        stripeAccount: action.response.data.stripeAccount,
      };
    default:
      return state;
  }
};

const booking = (state = initialState.booking, action) => {
  switch (action.type) {
    case FETCH_EVENT_START:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case FETCH_EVENT_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
      });

    case FETCH_EVENT_SUCCESS:
      return Object.assign({}, state, {
        isFetched: true,
        isFetching: false,
        item: action.response.data.bookingInformation,
      });

    default:
      return state;
  }
};

const performances = (state = initialState.performances, action) => {
  switch (action.type) {
    case FETCH_PERFORMANCES_START:
      return Object.assign({}, state, {
        isFetching: true,
        items: [],
        isFetched: false,
        pages: 1,
      });

    case FETCH_PERFORMANCES_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
      });

    case FETCH_PERFORMANCES_SUCCESS:
      return Object.assign({}, state, {
        items: state.items.concat(action.response.data),
        pages: action.response._metadata.totalPages,
      });
    case FETCH_ALL_PERFORMANCES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
      });

    default:
      return state;
  }
};

const forgotPassword = (state = initialState.forgotPassword, action) => {
  switch (action.type) {
    case SEND_FORGOT_PASSWORD_EMAIL_START:
      return Object.assign({
        isSent: false,
        isSending: true,
        sendError: null,
      });

    case SEND_FORGOT_PASSWORD_EMAIL_FAIL:
      return Object.assign({
        isSent: false,
        sendError: action.error,
        isSending: false,
      });

    case SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
      return Object.assign({
        isSending: false,
        isSent: true,
        sendError: null,
      });
    default:
      return state;
  }
};

const capacity = (state = initialState.capacity, action) => {
  switch (action.type) {
    case RECEIVE_TICKETS: {
      let groups = state.items;
      let hasGroups = state.hasGroups;

      // group tickets by capacity group
      for (let ticket of action.response.data) {
        if (ticket.capacity && !groups[ticket.capacity.id]) {
          groups[ticket.capacity.id] = ticket.capacity;
          hasGroups = true;
        }
      }

      return Object.assign({}, state, {
        hasGroups: hasGroups,
        items: groups,
      });
    }

    case BASKET_ADD_TICKET: {
      if (action.ticket.capacity) {
        let perfGroup = Object.assign(
          {},
          state.items[action.ticket.capacity.id],
          {
            left: state.items[action.ticket.capacity.id].left - action.quantity,
          }
        );
        let groups = Object.assign({}, state.items, {
          [action.ticket.capacity.id]: perfGroup,
        });

        return Object.assign({}, state, {
          items: groups,
        });
      }
      return state;
    }

    case BASKET_REMOVE_TICKET: {
      if (action.ticket.capacity) {
        let perfGroup = Object.assign(
          {},
          state.items[action.ticket.capacity.id],
          {
            left: state.items[action.ticket.capacity.id].left + action.quantity,
          }
        );
        let groups = Object.assign({}, state.items, {
          [action.ticket.capacity.id]: perfGroup,
        });

        return Object.assign({}, state, {
          items: groups,
        });
      }
      return state;
    }

    default:
      return state;
  }
};

const discount = (state = initialState.discount, action) => {
  switch (action.type) {
    case APPLY_DISCOUNT_CODE_START:
      return Object.assign({}, state, {
        isApplying: true,
        discountError: null,
      });
    case CREATE_RESERVATION_SUCCESS:
    case APPLY_DISCOUNT_CODE_SUCCESS:
      return Object.assign({}, state, {
        isApplying: false,
        items: action.response.data.discount.discounts,
        ticketDiscount: action.response.data.discount.totalTicketDiscount,
        feeDiscount: action.response.data.discount.totalFeeDiscount,
      });
    case APPLY_DISCOUNT_CODE_FAIL:
      return Object.assign({}, state, {
        isApplying: false,
        discountError: action.response,
      });
    case CREATE_RESERVATION_START:
      return Object.assign({}, state, {
        discountError: null,
        items: [],
      });
    case BASKET_ADD_SEASON_TICKET:
      return {
        ...state,
        ticketDiscount: state.ticketDiscount + action.ticket.discount,
      };
    case BASKET_REMOVE_SEASON_TICKET:
      return {
        ...state,
        ticketDiscount: state.ticketDiscount - action.ticket.discount,
      };

    default:
      return state;
  }
};

const calendar = (state = initialState.showCalendar, action) => {
  switch (action.type) {
    case OPEN_CALENDAR:
      return true;
    case CLOSE_CALENDAR:
      return false;
    default:
      return state;
  }
};

const seatingChartsByPerformanceId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PERFORMANCES_SUCCESS: {
      let newCharts = action.response.data.reduce((charts, perf) => {
        if (perf.seatingChart && perf.seatingChart.key) {
          charts[perf.id] = {
            ...seatingChart(state[perf.id], action),
            publicKey: perf.seatingChart.key,
          };
        }
        return charts;
      }, {});
      return {
        ...state,
        ...newCharts,
      };
    }
    case CREATE_SEATING_RESERVATION_TOKEN_SUCCESS:
      return {
        ...state,
        [action.performanceId]: seatingChart(
          state[action.performanceId],
          action
        ),
      };
    default:
      return state;
  }
};

const seatingChart = (
  state = {
    reservationToken: null,
    loaded: false,
    publicKey: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_SEATING_RESERVATION_TOKEN_SUCCESS:
      return {
        ...state,
        reservationToken: action.reservationToken,
      };
    case FETCH_PERFORMANCES_SUCCESS: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

const currency = (state = "GBP", action) => {
  switch (action.type) {
    case RECEIVE_TICKETS:
      return action.response.data[0] ? action.response.data[0].currency : state;
    default:
      return state;
  }
};

const donation = (
  state = {
    showOther: false,
    amount: 0,
    giftAid: false,
  },
  action
) => {
  switch (action.type) {
    case ADD_DONATION:
      return {
        ...state,
        amount: parseFloat(action.amount),
      };
    case EXPIRED_RESERVATION_START:
    case LOGOUT_USER:
    case REMOVE_DONATION:
      return {
        ...state,
        amount: 0,
        showOther: false,
        giftAid: false,
      };
    case SHOW_OTHER_DONATION:
      return {
        ...state,
        showOther: true,
      };
    case HIDE_OTHER_DONATION:
      return {
        ...state,
        showOther: false,
      };
    case ADD_GIFT_AID:
      return {
        ...state,
        giftAid: true,
      };
    case REMOVE_GIFT_AID:
      return {
        ...state,
        giftAid: false,
      };
    default:
      return state;
  }
};

const customForm = (state = initialState.settings, action) => {
  switch (action.type) {
    case FETCH_FORM_START:
      return {
        item: {},
        isFetched: false,
        isLoading: true,
      };
    case FETCH_FORM_SUCCESS:
      return {
        ...state,
        item: action.response.data,
        isFetched: true,
        isLoading: false,
      };
    case FETCH_FORM_FAIL:
      return {
        item: {},
        isFetched: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

const waitingList = (state = initialState.waitingList, action) => {
  switch (action.type) {
    case JOIN_WAITING_LIST_START:
      return Object.assign({
        hasJoined: false,
        isSending: true,
        sendError: null,
      });

    case JOIN_WAITING_LIST_FAIL:
      return Object.assign({
        hasJoined: false,
        sendError: action.error,
        isSending: false,
      });

    case JOIN_WAITING_LIST_SUCCESS:
      return Object.assign({
        isSending: false,
        hasJoined: true,
        sendError: null,
      });
    case SELECT_PERFORMANCE:
      return Object.assign({
        isSending: false,
        hasJoined: false,
        sendError: null,
      });
    default:
      return state;
  }
};

const pastOrders = (
  state = {
    isFetching: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_PAST_ORDERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PAST_ORDERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.response.data,
      };
    case FETCH_PAST_ORDERS_FAIL:
      return {
        ...state,
        isFetching: false,
        currentPage: 1,
      };
    default:
      return state;
  }
};
const currentPage = (state = 1, action) => {
  switch (action.type) {
    case FETCH_PAST_ORDERS_START:
      return action.page;
    default:
      return state;
  }
};
const totalPages = (state = 1, action) => {
  switch (action.type) {
    case FETCH_PAST_ORDERS_SUCCESS:
      return action.response._metadata.totalPages;
    default:
      return state;
  }
};
const pastOrdersByPage = (
  state = {
    currentPage: 1,
    totalPages: 1,
  },
  action
) => {
  switch (action.type) {
    case FETCH_PAST_ORDERS_START:
    case FETCH_PAST_ORDERS_FAIL:
    case FETCH_PAST_ORDERS_SUCCESS:
      return {
        ...state,
        [action.page]: pastOrders(state[action.page], action),
        currentPage: currentPage(state.currentPage, action),
        totalPages: totalPages(state.totalPages, action),
      };
    case LOGOUT_USER:
      return {};
    default:
      return state;
  }
};

const selectedOrder = (state = null, action) => {
  switch (action.type) {
    case SELECT_PAST_ORDER:
      return {
        ...action.order,
      };
    case CANCEL_SELECT_ORDER:
      return null;
    case CANCEL_TICKET_START: {
      const ticketPurchases = state.ticketPurchases.map((tp) => {
        return {
          ...tp,
          receipts: tp.receipts.map((r) => {
            return {
              ...r,
              loading: r.id === action.receiptId,
            };
          }),
        };
      });
      return {
        ...state,
        ticketPurchases: [...ticketPurchases],
      };
    }
    case CANCEL_TICKET_SUCCESS: {
      const ticketPurchases = state.ticketPurchases.map((tp) => {
        return {
          ...tp,
          receipts: tp.receipts.map((r) => {
            return {
              ...r,
              cancelled: r.cancelled || r.id === action.receiptId,
              loading: r.id === action.receiptId ? !r.loading : r.loading,
            };
          }),
        };
      });
      return {
        ...state,
        ticketPurchases: [...ticketPurchases],
      };
    }
    case CANCEL_TICKET_FAIL: {
      const ticketPurchases = state.ticketPurchases.map((tp) => {
        return {
          ...tp,
          receipts: tp.receipts.map((r) => {
            return {
              ...r,
              loading: r.id === action.receiptId ? !r.loading : r.loading,
            };
          }),
        };
      });
      return {
        ...state,
        ticketPurchases: [...ticketPurchases],
      };
    }
    default:
      return state;
  }
};

const pastOrdersReducer = combineReducers({
  pastOrdersByPage,
  selectedOrder,
});

const createRootReducer = (history) =>
  combineReducers({
    currency: currency,
    donation: donation,
    router: connectRouter(history),
    auth: auth,
    user: user,
    event: event,
    product: product,
    basket: basket,
    reservation: reservation,
    order: order,
    flow: flow,
    settings: settings,
    booking: booking,
    performances: performances,
    forgotPassword: forgotPassword,
    capacity: capacity,
    discount: discount,
    selectedPerformance: selectedPerformance,
    selectedDate: selectedDate,
    ticketsByPerformance: ticketsByPerformance,
    seasonTicketsByPerformance,
    productsByCollection,
    collection,
    collections,
    showCalendar: calendar,
    seatingChartsByPerformanceId,
    form: formReducer,
    customForm,
    waitingList,
    pastOrders: pastOrdersReducer,
  });

export default createRootReducer;
