import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { joinWaitingList } from "actions";
import WaitingListForm from "components/WaitingListForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./WaitingList.module.css";

export class WaitingListView extends React.Component {
  handleSubmitClick = (values) => {
    return this.props.dispatch(joinWaitingList(values.email));
  };
  getSentSuccessMessage = () => {
    return (
      <div className={styles.waitingListSuccess}>
        <p>
          Your email has been added to the waiting list. If more tickets become
          available you will be contacted.
        </p>
        <div className={styles.loginButtonContainer}>
          <Link to={"/"} className={styles.loginButton}>
            Back to tickets
          </Link>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div className={styles.root}>
        <div className={styles.backButton}>
          <Link to={"/"}>
            <span className={styles.backButtonSpan}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back to tickets
          </Link>
        </div>
        <div className={styles.waitingListContainer}>
          <h2>Join Waiting List</h2>
          {this.props.hasJoined ? (
            this.getSentSuccessMessage()
          ) : (
            <WaitingListForm onSubmit={this.handleSubmitClick} />
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    hasJoined: state.waitingList.hasJoined,
  };
};

export default connect(mapStateToProps)(WaitingListView);
