import moment from "moment";

// see: http://momentjs.com/docs/#/displaying/format/ for formatting options
export const DATE_FORMATS = {
  NAV_DATE: "ddd D MMM",
  NAV_DATE_FULL: "ddd D MMM YYYY",
  NAV_TIME: "h:mm a",
  CALENDAR_TIME: "h:mma",
};

/**
 * Get the start date as a moment object
 *
 * @param {performance} The performance
 * @return {moment} The start date
 */
export const getPerformanceStartDate = (performance) => {
  if (performance.startTime) {
    return moment(performance.startDate + " " + performance.startTime);
  }

  return moment(performance.startDate);
};

/**
 * Get the end date as a moment
 *
 * @param {performance} The performance
 * @return {moment} The end date as a moment object
 */
export const getPerformanceEndDate = (performance) => {
  if (performance.endTime) {
    return moment(performance.endDate + " " + performance.endTime);
  }

  return moment(performance.endDate);
};

/**
 * Get a long date string for a single performance
 *
 * @param {object} performance The performance
 * @return {string} The formatted date
 */
export const getPerformanceDateString = (performance) => {
  let date = "";
  let time = "";
  const dateFormat = "ddd D MMM YYYY";
  const timeFormat = "h:mm A";

  let startDt = getPerformanceStartDate(performance);
  let endDt = getPerformanceEndDate(performance);

  if (performance.startDate === performance.endDate) {
    date = startDt.format(dateFormat);
  } else {
    date = startDt.format(dateFormat) + " - " + endDt.format(dateFormat);
  }

  if (performance.startTime) {
    if (performance.endTime) {
      time = startDt.format(timeFormat) + " - " + endDt.format(timeFormat);
    } else {
      time = startDt.format(timeFormat);
    }
  }

  return date + ", " + time;
};

/**
 * Returns a long date string for an event
 *
 * @param {object} event The event
 * @return {string} The long date string
 */
export const getLongDateString = (event) => {
  let perfsCount = 0;
  let firstPerf = null;
  let firstPerfDt = null;
  let lastPerf = null;
  let lastPerfDt = null;

  for (let venue of event.venues) {
    for (let venuePerf of venue.performances) {
      let startPerfDt = getPerformanceStartDate(venuePerf);
      let endPerfDt = getPerformanceEndDate(venuePerf);

      perfsCount += 1;

      if (firstPerfDt === null || startPerfDt <= firstPerfDt) {
        firstPerfDt = startPerfDt;
        firstPerf = venuePerf;
      }

      if (lastPerfDt === null || endPerfDt >= lastPerfDt) {
        lastPerfDt = endPerfDt;
        lastPerf = venuePerf;
      }
    }
  }

  // this shouldn't have, but just in case
  if (perfsCount === 0) {
    return "Past Event";
  }

  let startDayStr = moment(firstPerfDt).format("ddd D MMM YYYY");
  let endDayStr = moment(lastPerfDt).format("ddd D MMM YYYY");
  let startTimeStr = moment(firstPerfDt).format("h:mm A");
  let endTimeStr = moment(lastPerfDt).format("h:mm A");

  // totally different days
  if (startDayStr !== endDayStr) {
    // start & end times exist
    if (firstPerf.startTime && lastPerf.endTime) {
      return (
        startDayStr +
        ", " +
        startTimeStr +
        " - " +
        endDayStr +
        ", " +
        endTimeStr
      );
    }

    // only start time available
    if (firstPerf.startTime) {
      return startDayStr + " - " + endDayStr + ", " + startTimeStr;
    }

    return startDayStr + " - " + endDayStr;
  }

  // -- same day

  // there are start/end times
  if (firstPerf.startTime && firstPerf.endTime) {
    // different start/end times
    if (firstPerf.startTime !== firstPerf.endTime) {
      return startDayStr + ", " + startTimeStr + " - " + endTimeStr;
    }

    // same start/end time
    return startDayStr + ", " + startTimeStr;
  }

  if (firstPerf.startTime) {
    return startDayStr + ", " + startTimeStr;
  }

  return startDayStr;
};

/**
 * Returns true if d1 and d2 are the same
 *
 * @param {Date} d1 The first date
 * @param {Date} d2 The second date
 * @return {boolean} true if they are the same day, false otherwise
 */
export const isSameDay = (d1, d2) => {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
};
