// Selectors
import { getCollections, getProductId, getEventId } from "selectors";

// Utils
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const Home = () => {
  const eventId = useSelector(getEventId);
  const collections = useSelector(getCollections);
  const productId = useSelector(getProductId);

  if (eventId) {
    return <Redirect to="/event" />;
  } else if (collections) {
    const collectionId = collections[0];
    return <Redirect to={`/product-collection/${collectionId}`} />;
  } else if (productId) {
    return <Redirect to={`/product`} />;
  }
  return null;
};

export { Home };
