import React from "react";
import PropTypes from "prop-types";
import LaddaButton from "views/LaddaButton";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import { getBookingFeeText } from "utils/Text";
import styles from "./TicketTableFooter.module.css";

export default class TicketTableFooter extends React.Component {
  static propTypes = {
    basketItemCount: PropTypes.number.isRequired,
    basketTotal: PropTypes.number.isRequired,
    basketTax: PropTypes.number.isRequired,
    basketCurrency: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    onBookClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    basketIsValid: PropTypes.bool,
  };

  /**
   * Returns the HTML for the total
   *
   */
  getTotalBox = (currency, total) => {
    const feeText = this.props.basketTax > 0 ? getBookingFeeText(currency) : "";
    const symbol = currencyCodeToSymbol(currency);
    return (
      <div className={styles.totalBox}>
        <div className={styles.totalPrice}>
          TOTAL:{" "}
          <span ref="total" className={styles.totalPriceFigure}>
            {symbol} {formatPrice(total)}
          </span>
        </div>
        <small>{feeText}</small>
      </div>
    );
  };

  /**
   * Returns the total value of the basket
   *
   */
  getBasketTotal = () => {
    let total = this.props.basketTotal;
    let discount = this.props.discount ? this.props.discount : 0;
    return total - discount;
  };

  render() {
    let total = this.getBasketTotal();

    let currency = null;
    let totalBox = null;
    let bookButtonClassName = this.props.basketIsValid
      ? `${styles.bookButton} ticketTableFooterBookButton`
      : `${styles.bookButtonDisabled} ticketTableFooterBookButtonDisabled`;

    // only show the total box if there are tickets in the basket
    if (this.props.basketItemCount > 0) {
      currency = this.props.basketCurrency;
      totalBox = this.getTotalBox(currency, total);
    }
    return (
      <div className={styles.root}>
        <div className={styles.bookButtonContainer}>
          {total > 0 ? totalBox : ""}
          {this.props.isSoldOut && this.props.showWaitingList ? (
            <LaddaButton
              className={`${styles.bookButton} ticketTableFooterBookButton`}
              onClick={this.props.onJoinWaitingListClick}
              href="#"
            >
              Join Waiting List
            </LaddaButton>
          ) : (
            <LaddaButton
              loading={this.props.loading}
              className={bookButtonClassName}
              disabled={!this.props.basketIsValid}
              onClick={this.props.onBookClick}
              href="#"
            >
              Continue
            </LaddaButton>
          )}
          {this.props.listingsLink && (
            <span>
              <span className={styles.ctaDivider}>OR</span>
              <LaddaButton
                className={`${styles.bookButton} ticketTableFooterBookButton`}
                onClick={this.props.onListingsClick}
              >
                Continue Shopping
              </LaddaButton>
            </span>
          )}
        </div>
        <div className={styles.clearFix}></div>
      </div>
    );
  }
}
