import { forwardRef } from "react";
import styles from "./LinkButton.module.css";

const LinkButton = forwardRef((props, ref) => {
  const { className, ...otherProps } = props;
  const classname = className
    ? `${styles.linkButton} ${className}`
    : styles.linkButton;
  return (
    <button ref={ref} {...otherProps} className={classname}>
      {props.children}
    </button>
  );
});

export { LinkButton };
