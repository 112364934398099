import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormField from '../FormField';
import LaddaButton from '../LaddaButton';
import styles from './DonationForm.module.css';

const validate = values => {
  const errors = {};
  if (!values.amount) {
    errors.amount = 'Please enter an amount';
  }
  if (values.amount < 0) {
    errors.amount = 'Donation must be a positive amount';
  }
  return errors;
};

let DonationForm = ({
  error,
  handleSubmit,
  onCancelDonationClick,
  pristine,
  submitting,
  donationText
}) => {
  return (
    <form onSubmit={handleSubmit} className={styles.donationForm}>
      <h4>{donationText}</h4>
      <div>
        {error && <div className={styles.formError}>{error}</div>}
        <div>
          <Field
            formClass={styles.donationFormInput}
            name='amount'
            component={FormField}
            type='number'
            label='Donation Amount'
            hideLabel={true}
          />
          <LaddaButton
            type='submit'
            disabled={submitting}
            className={`${styles.donationCancelButton} donationFormDonationCancelButton`}
            loading={submitting}
            onClick={onCancelDonationClick}
          >
            Cancel
          </LaddaButton>
          <LaddaButton
            type='submit'
            disabled={submitting}
            className={`${styles.donationApplyButton} donationFormDonationApplyButton`}
            loading={submitting}
            onClick={handleSubmit}
          >
            Add
          </LaddaButton>
        </div>
      </div>
    </form>
  );
};

DonationForm = reduxForm({
  form: 'donation',
  validate
})(DonationForm);

export default DonationForm;
