import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormField from '../FormField';
import LaddaButton from '../LaddaButton';
import styles from './ForgotPasswordForm.module.css';

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

let ForgotPasswordForm = ({ error, handleSubmit, pristine, submitting }) => {
  return (
    <form onSubmit={handleSubmit} className={styles.loginFormForm}>
      <div className={styles.loginFormForm}>
        {error && <div className={styles.formError}>{error}</div>}
        <div>
          <Field
            name='email'
            component={FormField}
            type='email'
            label='Email Address'
            hideLabel={true}
          />
        </div>
        <div>
          <LaddaButton
            type='submit'
            disabled={submitting}
            className={`${styles.submitButton} forgotPasswordFormSubmitButton`}
            loading={submitting}
            onClick={handleSubmit}
          >
            Reset my password
          </LaddaButton>
        </div>
      </div>
    </form>
  );
};

ForgotPasswordForm = reduxForm({
  form: 'forgotPassword',
  validate
})(ForgotPasswordForm);

export default ForgotPasswordForm;
