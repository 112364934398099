import React from "react";
import PropTypes from "prop-types";
import SeasonTicketRow from "components/SeasonTicketRow";
import styles from "./SeasonTicketTable.module.css";

export default class SeasonTicketTable extends React.Component {
  static propTypes = {
    seasonTickets: PropTypes.array,
    performance: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    onIncrementClick: PropTypes.func.isRequired,
    onDecrementClick: PropTypes.func.isRequired,
    capacity: PropTypes.object.isRequired,
  };

  render = () => {
    if (!this.props.seasonTickets || this.props.seasonTickets.length === 0) {
      return null;
    }

    let tickets = this.props.seasonTickets.map((seasonTicket) => {
      const { fixedDiscount = 0, percentageDiscount = 0 } =
        seasonTicket.discount || {};
      let ticketTotals = seasonTicket.tickets.reduce(
        (totals, seasonTicketItem, index) => {
          let { ticket = seasonTicketItem, quantity = 1 } = seasonTicketItem;
          totals.feeTotal += ticket.feeTotal * quantity;
          totals.totalExFees += ticket.totalExFees * quantity;
          totals.total += ticket.total * quantity;
          totals.currency = ticket.currency;
          totals.discount += fixedDiscount * quantity;
          totals.discount +=
            percentageDiscount *
            (totals.feesPassed ? ticket.totalExFees : ticket.total) *
            quantity;
          totals.inventoryLeft =
            totals.inventoryLeft >= 0
              ? Math.min(
                  totals.inventoryLeft,
                  ~~(ticket.inventoryLeft / quantity)
                )
              : ~~(ticket.inventoryLeft / quantity);

          if (ticket.feesPassed) {
            totals.feesPassed = true;
          }
          return totals;
        },
        {
          feeTotal: 0,
          feesPassed: false,
          totalExFees: 0,
          total: 0,
          currency: null,
          discount: 0,
        }
      );
      return {
        id: seasonTicket.id,
        description: seasonTicket.description,
        ...ticketTotals,
      };
    });

    let priceSort = (a, b) => {
      if (a.total < b.total) {
        return 1;
      } else {
        return -1;
      }
    };

    // copy the tickets prop & sort
    tickets = tickets.sort(priceSort);

    let ticketRowNodes = tickets.map((ticket) => {
      let quantity = null;
      let basketItem = this.props.basket[ticket.id];
      let capGroupRef = null;

      if (basketItem) {
        quantity = basketItem.quantity;
      } else {
        quantity = 0;
      }

      // use cap group in global state, rather than ticket.capacity,
      // so that different tickets will reference the same object rather
      // than seperate copies
      if (ticket.capacity) {
        capGroupRef = this.props.capacity[ticket.capacity.id];
      }

      return (
        <SeasonTicketRow
          quantity={quantity}
          key={ticket.id}
          ticket={ticket}
          performance={this.props.performance}
          capacity={capGroupRef}
          onIncrementClick={this.props.onIncrementClick}
          onDecrementClick={this.props.onDecrementClick}
        />
      );
    });

    return (
      <div className={styles.container}>
        <h1 className={styles.title}>Multi-Passes</h1>
        <table className={`${styles.ticketTable} seasonTicketTableTicketTable`}>
          <tbody>{ticketRowNodes}</tbody>
        </table>
      </div>
    );
  };
}
