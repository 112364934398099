const postToParent = (trackingEvent, obj) => {
  if ("parentIFrame" in window) {
    window.parentIFrame.sendMessage({
      messageType: "tracker",
      type: trackingEvent,
      obj: obj,
    });
  }
};

const eventViewed = (obj) => {
  postToParent("eventViewed", obj);
};

const performanceViewed = (obj) => {
  postToParent("performanceViewed", obj);
};

const addToBasket = (obj) => {
  postToParent("addToBasket", obj);
};

const startedCheckout = (obj) => {
  postToParent("startedCheckout", obj);
};

export const LuAnalytics = {
  eventViewed,
  addToBasket,
  performanceViewed,
  startedCheckout,
};
