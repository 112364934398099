/**
 * Returns the default card that wil be used for payment
 *
 * @param {array} cards The list of cards
 * @return object
 */
export const getDefaultCard = (cards) => {
  for (let card of cards) {
    if (card.item.default === true) {
      return card;
    }
  }
  return false;
};

export const getBasketContentsHash = (basket) => {
  let hash = basket.tickets.allIds.reduce((itemId, hash) => {
    let item = basket.tickets.byId[itemId];
    return (hash += `${item.ticket.id}:${item.quantity}`);
  }, "");
  hash = basket.seasonTickets.allIds.reduce((itemId, hash) => {
    let item = basket.seasonTickets.byId[itemId];
    return (hash += `${item.ticket.id}:${item.quantity}`);
  }, hash);
  return hash;
};

export const parseApiError = (json) => {
  let errorObj = {};
  if (json._metadata.errors) {
    errorObj = json._metadata.errors.reduce((obj, err) => {
      obj[err.attribute] = err.messages;
      return obj;
    }, {});
  } else {
    errorObj["_error"] = json._metadata.message;
  }
  return errorObj;
};

export const parseStripeError = (json) => {
  return { _error: json.message };
};

export const networkError = () => {
  return {
    _error:
      "Unable connect to the server, please check your network connection and try again",
  };
};
