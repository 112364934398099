import React from "react";
import PropTypes from "prop-types";
import ProductRow from "components/ProductRow";
import styles from "./ProductTable.module.css";

export default class ProductTable extends React.Component {
  static propTypes = {
    products: PropTypes.array,
    basket: PropTypes.object.isRequired,
    onIncrementClick: PropTypes.func.isRequired,
    onDecrementClick: PropTypes.func.isRequired,
  };

  render = () => {
    if (!this.props.products || this.props.products.length === 0) {
      return (
        <div className={styles.noTickets}>
          <h3>There are no products available to purchase.</h3>
        </div>
      );
    }

    let priceSort = (a, b) => {
      if (a.total < b.total) {
        return 1;
      } else {
        return -1;
      }
    };

    // copy the tickets prop & sort
    let products = this.props.products.slice();
    products.sort(priceSort);

    let productRowNodes = products.map((product) => {
      let basketItem = this.props.basket[product.id];
      let quantity = basketItem ? basketItem.quantity : 0;

      return (
        <ProductRow
          quantity={quantity}
          key={product.id}
          product={product}
          onIncrementClick={this.props.onIncrementClick}
          onDecrementClick={this.props.onDecrementClick}
        />
      );
    });

    return (
      <div className={styles.container}>
        <table className={`${styles.ticketTable} ticketTableTicketTable`}>
          <tbody>{productRowNodes}</tbody>
        </table>
      </div>
    );
  };
}
