import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { currencyCodeToSymbol, formatPrice } from 'utils/Currency';
import FormField from '../FormField';
import LaddaButton from '../LaddaButton';
import styles from './GiftAidForm.module.css';

const validate = values => {
  const errors = {};
  if (!values.giftAid) {
    errors.giftAid =
      'Please tick to confirm you wish to Gift Aid your donation';
  }
  return errors;
};

let GiftAidForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  charityName,
  donationAmount,
  currency
}) => {
  return (
    <div className={styles.form}>
      <p>
        Boost your donation by 25p of Gift Aid for every £1 you donate. Gift aid
        is reclaimed by the charity from the tax you pay for the current tax
        year.
      </p>
      <h4>
        In order to Gift Aid your donation you <u>must</u> tick the box below:
      </h4>
      <div className={styles.giftAidCheckboxContainer}>
        <div className={styles.giftAidCheckbox}>
          <Field
            name='giftAid'
            component={FormField}
            type='checkbox'
            hideLabel={true}
          />
        </div>
        <span
          className={styles.giftAidCheckLabel}
        >{`I want to Gift Aid my donation of: ${currencyCodeToSymbol(
          currency
        )}${formatPrice(donationAmount)} to ${charityName}`}</span>
      </div>
      <div className={styles.giftAidDeclarationContainer}>
        <p className={styles.giftAidDeclaration}>
          I am a UK taxpayer and understand that if I pay less Income Tax and/or
          Capital Gains Tax in the current tax year than the amount of Gift Aid
          claimed on all my donations it is my responsibility to pay any
          difference.
        </p>
        <p className={styles.giftAidDeclaration}>
          Please notify the charity if you:
        </p>
        <ul>
          <li className={styles.giftAidDeclarationBullet}>
            want to cancel this declaration
          </li>
          <li className={styles.giftAidDeclarationBullet}>
            change your name or home address
          </li>
          <li className={styles.giftAidDeclarationBullet}>
            no longer pay sufficient tax on your income and/or capital gains
          </li>
        </ul>
        <p className={styles.giftAidDeclaration}>
          If you pay Income Tax at the higher or additional rate and want to
          receive the additional tax relief due to you, you must include all
          your Gift Aid donations on your Self-Assessment tax return or ask HM
          Revenue and Customs to adjust your tax code.
        </p>
      </div>
      <div>
        <LaddaButton
          className={`${styles.confirmButton} giftAidFormConfirmButton`}
          loading={submitting}
          onClick={handleSubmit}
          disabled={submitting}
        >
          Confirm and Continue
        </LaddaButton>
      </div>
    </div>
  );
};

GiftAidForm = reduxForm({
  form: 'giftAid',
  validate
})(GiftAidForm);

export default GiftAidForm;
