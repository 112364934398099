import React from "react";
import styles from "./DonationOptions.module.css";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import { LinkButton } from "components/LinkButton";

const DonationOptions = ({
  options,
  currency,
  currentDonation,
  onDonationClick,
  onOtherDonationClick,
  donationText,
}) => {
  const getButton = (amount) => {
    let styleName =
      amount === currentDonation
        ? `${styles.donationButton} ${styles.donationButtonSelected} donationOptionsDonationButton donationOptionsDonationButtonSelected`
        : `${styles.donationButton} donationOptionsDonationButton`;
    return (
      <LinkButton
        key={amount}
        onClick={() => onDonationClick(amount)}
        className={styleName}
      >
        {currencyCodeToSymbol(currency)}
        {formatPrice(amount)}
      </LinkButton>
    );
  };
  const otherText =
    currentDonation > 0 && !options.includes(currentDonation)
      ? `${currencyCodeToSymbol(currency)}${formatPrice(currentDonation)}`
      : "Other";

  const otherStyleName =
    currentDonation > 0 && !options.includes(currentDonation)
      ? `${styles.donationButton} ${styles.donationButtonLast} ${styles.donationButtonSelected} donationOptionsDonationButton .donationOptionsDonationButtonSelected`
      : `${styles.donationButton} ${styles.donationButtonLast} donationOptionsDonationButton`;

  return (
    <div className={styles.donationBlock}>
      <h4>Make a donation</h4>
      <p className={styles.donationText}>{donationText}</p>
      {options.map((amount) => getButton(amount))}
      <LinkButton
        onClick={() => onOtherDonationClick()}
        className={otherStyleName}
      >
        {otherText}
      </LinkButton>
    </div>
  );
};

export default DonationOptions;
