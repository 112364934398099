import React from "react";
import { Field, reduxForm } from "redux-form";
import FormField from "../FormField";
import LaddaButton from "../LaddaButton";
import styles from "./RegisterForm.module.css";

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "First name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }
  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Your password needs to be at least 6 characters long";
  }
  return errors;
};

const required = (value) => (value ? undefined : "Required");
const phoneNumber = (value) =>
  value && !/^([+]|[0-9])([0-9]{8,15})$/i.test(value)
    ? "Invalid phone number, please only include digits"
    : undefined;

let RegisterForm = (
  { includePhone, error, handleSubmit, pristine, submitting, optInText },
  props
) => {
  return (
    <form onSubmit={handleSubmit} className={styles.registerForm}>
      {error && <div className={styles.formError}>{error}</div>}
      <div className={styles.firstName}>
        <Field
          name="firstName"
          component={FormField}
          type="text"
          label="First Name"
          hideLabel={true}
          autoComplete="given-name"
        />
      </div>
      <div className={styles.lastName}>
        <Field
          name="lastName"
          component={FormField}
          type="text"
          label="Last Name"
          hideLabel={true}
          autoComplete="family-name"
        />
      </div>
      <div>
        <Field
          name="email"
          component={FormField}
          type="email"
          label="Email Address"
          hideLabel={true}
          autoComplete="email"
        />
      </div>
      {includePhone && (
        <div>
          <Field
            name="telephone"
            component={FormField}
            type="tel"
            label="Phone Number"
            hideLabel={true}
            autoComplete="tel"
            validate={[required, phoneNumber]}
          />
        </div>
      )}
      <div>
        <Field
          name="password"
          component={FormField}
          type="password"
          label="Create Password"
          hideLabel={true}
          autoComplete="new-password"
        />
      </div>
      {optInText && (
        <div className={styles.checkbox}>
          <Field
            name="optIn"
            component={FormField}
            type="checkbox"
            label={optInText}
          />
        </div>
      )}
      <div>
        <Field
          name="optInText"
          component={FormField}
          type="hidden"
          hideLabel={true}
        />
      </div>
      <div>
        <LaddaButton
          type="submit"
          disabled={submitting}
          className={`${styles.reviewButton} registerFormReviewButton`}
          loading={submitting}
          onClick={handleSubmit}
        >
          Continue
        </LaddaButton>
      </div>
    </form>
  );
};

RegisterForm = reduxForm({
  form: "registerForm",
  validate,
})(RegisterForm);

export default RegisterForm;
