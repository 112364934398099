import React from "react";
import { reduxForm } from "redux-form";
import { SubmissionError } from "redux-form";
import { parseStripeError } from "utils/Utils";
import CardSection from "components/CardSection";
import styles from "../views/Confirm/Confirm.module.css";
import LaddaButton from "components/LaddaButton";
import Media from "react-media";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

let PaymentForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  onPaymentMethodSuccess,
  onPaymentIntentSuccess,
  initialError,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleStripeJsResult = (result) => {
    if (result.error) {
      throw new SubmissionError(parseStripeError(result.error));
    } else {
      return onPaymentIntentSuccess(result.paymentIntent.id);
    }
  };

  const handleServerResponse = (response) => {
    if (response.requiresAction) {
      // Use Stripe.js to handle required card action
      return stripe
        .handleCardAction(response.paymentIntentClientSecret)
        .then(handleStripeJsResult);
    }
  };
  const submit = (values) => {
    const cardElement = elements.getElement(CardElement);
    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then(
        ({ paymentMethod, error }) => {
          if (error) {
            throw new SubmissionError(parseStripeError(error));
          }
          return onPaymentMethodSuccess(paymentMethod, values).then(
            ({ response }) => {
              return handleServerResponse(response.data);
            }
          );
        },
        (error) => console.log(error)
      );
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      {error && <div className={styles.formError}>{error}</div>}
      {!error && initialError && (
        <div className={styles.formError}>{initialError}</div>
      )}
      <Media query={{ maxWidth: 479 }}>
        {(matches) =>
          matches ? (
            <CardSection fontSize="14px" />
          ) : (
            <CardSection fontSize="17px" />
          )
        }
      </Media>
      <LaddaButton
        disabled={submitting}
        loading={submitting}
        onClick={handleSubmit(submit)}
        className={`${styles.orderButton} paymentFormOrderButton`}
      >
        Confirm and Buy Now
      </LaddaButton>
    </form>
  );
};

export default PaymentForm = reduxForm({
  form: "payment",
})(PaymentForm);
