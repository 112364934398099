import React from "react";
import PropTypes from "prop-types";
import Countdown from "components/Countdown";
import styles from "./Header.module.css";

export default class Header extends React.Component {
  static propTypes = {
    reservation: PropTypes.object,
    order: PropTypes.object,
    onCountdownFinish: PropTypes.func.isRequired,
  };

  render() {
    let countdown = null;

    if (this.props.reservation.isReserved && !this.props.order.isCreated) {
      countdown = (
        <Countdown
          onCountdownFinish={this.props.onCountdownFinish}
          reservation={this.props.reservation.item}
        />
      );
    }

    return <div className={styles.root}>{countdown}</div>;
  }
}
